/* web screen size */
/* web screen size 1040px */
/* Ipad Vertical and smaller 768px */
/* Iphone 6 Plus and smaller 480px */
/* Iphone 5 and smaller 320 px*/
/* Desktop */
/* Mobile */
.u-color-primary {
  color: #da151f !important;
}
.u-b-color-primary {
  background-color: #da151f !important;
}
.u-color-secondary {
  color: #db5656 !important;
}
.u-b-color-secondary {
  background-color: #db5656 !important;
}
.u-color-gray {
  color: #666666 !important;
}
.u-b-color-gray {
  background-color: #666666 !important;
}
.u-color-light-gray {
  color: #999999 !important;
}
.u-b-color-light-gray {
  background-color: #999999 !important;
}
.u-color-dark-gray {
  color: #222222 !important;
}
.u-b-color-dark-gray {
  background-color: #222222 !important;
}
.u-color-white {
  color: white !important;
}
.u-b-color-white {
  background-color: white !important;
}
html,
body {
  height: 100%;
}
body {
  z-index: 0;
  width: 100%;
  background-position: top center;
  background-repeat: no-repeat;
  font-family: "PingFang SC" !important;
}
@media screen and (max-width: 960px) {
  body {
    -webkit-text-size-adjust: 100% !important;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  body {
    font-family: "PingFang SC" !important;
  }
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  outline: none;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after {
  content: "";
  content: none;
}
q:before,
q:after {
  content: "";
  content: none;
}
hr {
  border: 1px solid #eeeeee;
  border-bottom: none;
}
.clear {
  position: relative;
  clear: both;
}
.left {
  float: left;
}
.right {
  float: right;
}
strong {
  font-weight: bold;
}
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
  height: auto;
}
textarea {
  outline: none;
}
.nowrap {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.u-align-baseline {
  vertical-align: baseline !important;
}
.u-align-top {
  vertical-align: top !important;
}
.u-align-middle {
  vertical-align: middle !important;
}
.u-align-bottom {
  vertical-align: bottom !important;
}
.u-align-text-bottom {
  vertical-align: text-bottom !important;
}
.u-align-text-top {
  vertical-align: text-top !important;
}
.u-d-none {
  display: none !important;
}
.u-d-inline {
  display: inline !important;
}
.u-d-inline-block {
  display: inline-block !important;
}
.u-d-block {
  display: block !important;
}
.u-d-table {
  display: table !important;
}
.u-d-table-row {
  display: table-row !important;
}
.u-d-table-cell {
  display: table-cell !important;
}
.u-d-flex {
  display: flex !important;
}
.u-d-inline-flex {
  display: inline-flex !important;
}
.u-d-inherit {
  display: inherit !important;
}
@media (min-width: 0) {
  .u-d-none-xs {
    display: none !important;
  }
  .u-d-inline-xs {
    display: inline !important;
  }
  .u-d-inline-block-xs {
    display: inline-block !important;
  }
  .u-d-block-xs {
    display: block !important;
  }
  .u-d-table-xs {
    display: table !important;
  }
  .u-d-table-row-xs {
    display: table-row !important;
  }
  .u-d-table-cell-xs {
    display: table-cell !important;
  }
  .u-d-flex-xs {
    display: flex !important;
  }
  .u-d-inline-flex-xs {
    display: inline-flex !important;
  }
}
@media (min-width: 576px) {
  .u-d-none-sm {
    display: none !important;
  }
  .u-d-inline-sm {
    display: inline !important;
  }
  .u-d-inline-block-sm {
    display: inline-block !important;
  }
  .u-d-block-sm {
    display: block !important;
  }
  .u-d-table-sm {
    display: table !important;
  }
  .u-d-table-row-sm {
    display: table-row !important;
  }
  .u-d-table-cell-sm {
    display: table-cell !important;
  }
  .u-d-flex-sm {
    display: flex !important;
  }
  .u-d-inline-flex-sm {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .u-d-none-md {
    display: none !important;
  }
  .u-d-inline-md {
    display: inline !important;
  }
  .u-d-inline-block-md {
    display: inline-block !important;
  }
  .u-d-block-md {
    display: block !important;
  }
  .u-d-table-md {
    display: table !important;
  }
  .u-d-table-row-md {
    display: table-row !important;
  }
  .u-d-table-cell-md {
    display: table-cell !important;
  }
  .u-d-flex-md {
    display: flex !important;
  }
  .u-d-inline-flex-md {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .u-d-none-lg {
    display: none !important;
  }
  .u-d-inline-lg {
    display: inline !important;
  }
  .u-d-inline-block-lg {
    display: inline-block !important;
  }
  .u-d-block-lg {
    display: block !important;
  }
  .u-d-table-lg {
    display: table !important;
  }
  .u-d-table-row-lg {
    display: table-row !important;
  }
  .u-d-table-cell-lg {
    display: table-cell !important;
  }
  .u-d-flex-lg {
    display: flex !important;
  }
  .u-d-inline-flex-lg {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .u-d-none-xl {
    display: none !important;
  }
  .u-d-inline-xl {
    display: inline !important;
  }
  .u-d-inline-block-xl {
    display: inline-block !important;
  }
  .u-d-block-xl {
    display: block !important;
  }
  .u-d-table-xl {
    display: table !important;
  }
  .u-d-table-row-xl {
    display: table-row !important;
  }
  .u-d-table-cell-xl {
    display: table-cell !important;
  }
  .u-d-flex-xl {
    display: flex !important;
  }
  .u-d-inline-flex-xl {
    display: inline-flex !important;
  }
}
@media (min-width: 1400px) {
  .u-d-none-xxl {
    display: none !important;
  }
  .u-d-inline-xxl {
    display: inline !important;
  }
  .u-d-inline-block-xxl {
    display: inline-block !important;
  }
  .u-d-block-xxl {
    display: block !important;
  }
  .u-d-table-xxl {
    display: table !important;
  }
  .u-d-table-row-xxl {
    display: table-row !important;
  }
  .u-d-table-cell-xxl {
    display: table-cell !important;
  }
  .u-d-flex-xxl {
    display: flex !important;
  }
  .u-d-inline-flex-xxl {
    display: inline-flex !important;
  }
}
.u-flex-grow-full {
  flex-grow: 1;
}
.u-flex-row {
  flex-direction: row;
}
.u-flex-column {
  flex-direction: column;
}
.u-flex-row-reverse {
  flex-direction: row-reverse;
}
.u-flex-column-reverse {
  flex-direction: column-reverse;
}
.u-flex-wrap {
  flex-wrap: wrap;
}
.u-flex-nowrap {
  flex-wrap: nowrap;
}
.u-flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}
.u-justify-content-start {
  justify-content: flex-start;
}
.u-justify-content-end {
  justify-content: flex-end;
}
.u-justify-content-center {
  justify-content: center;
}
.u-justify-content-between {
  justify-content: space-between;
}
.u-justify-content-around {
  justify-content: space-around;
}
.u-justify-content-evenly {
  justify-content: space-evenly;
}
.u-align-items-start {
  align-items: flex-start;
}
.u-align-items-end {
  align-items: flex-end;
}
.u-align-items-center {
  align-items: center;
}
.u-align-items-baseline {
  align-items: baseline;
}
.u-align-items-stretch {
  align-items: stretch;
}
.u-align-content-start {
  align-content: flex-start;
}
.u-align-content-end {
  align-content: flex-end;
}
.u-align-content-center {
  align-content: center;
}
.u-align-content-between {
  align-content: space-between;
}
.u-align-content-around {
  align-content: space-around;
}
.u-align-content-stretch {
  align-content: stretch;
}
.u-align-self-auto {
  align-self: auto;
}
.u-align-self-start {
  align-self: flex-start;
}
.u-align-self-end {
  align-self: flex-end;
}
.u-align-self-center {
  align-self: center;
}
.u-align-self-baseline {
  align-self: baseline;
}
@media screen and (min-width: 0) {
  .u-flex-grow-xs-full {
    flex-grow: 1 !important;
  }
  .u-flex-xs-row {
    flex-direction: row !important;
  }
  .u-flex-xs-column {
    flex-direction: column !important;
  }
  .u-flex-xs-row-reverse {
    flex-direction: row-reverse !important;
  }
  .u-flex-xs-column-reverse {
    flex-direction: column-reverse !important;
  }
  .u-flex-xs-wrap {
    flex-wrap: wrap !important;
  }
  .u-flex-xs-nowrap {
    flex-wrap: nowrap !important;
  }
  .u-flex-xs-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .u-justify-content-xs-start {
    justify-content: flex-start !important;
  }
  .u-justify-content-xs-end {
    justify-content: flex-end !important;
  }
  .u-justify-content-xs-center {
    justify-content: center !important;
  }
  .u-justify-content-xs-between {
    justify-content: space-between !important;
  }
  .u-justify-content-xs-around {
    justify-content: space-around !important;
  }
  .u-justify-content-xs-evenly {
    justify-content: space-evenly !important;
  }
  .u-align-items-xs-start {
    align-items: flex-start !important;
  }
  .u-align-items-xs-end {
    align-items: flex-end !important;
  }
  .u-align-items-xs-center {
    align-items: center !important;
  }
  .u-align-items-xs-baseline {
    align-items: baseline !important;
  }
  .u-align-items-xs-stretch {
    align-items: stretch !important;
  }
  .u-align-content-xs-start {
    align-content: flex-start !important;
  }
  .u-align-content-xs-end {
    align-content: flex-end !important;
  }
  .u-align-content-xs-center {
    align-content: center !important;
  }
  .u-align-content-xs-between {
    align-content: space-between !important;
  }
  .u-align-content-xs-around {
    align-content: space-around !important;
  }
  .u-align-content-xs-stretch {
    align-content: stretch !important;
  }
  .u-align-self-xs-auto {
    align-self: auto !important;
  }
  .u-align-self-xs-start {
    align-self: flex-start !important;
  }
  .u-align-self-xs-end {
    align-self: flex-end !important;
  }
  .u-align-self-xs-center {
    align-self: center !important;
  }
  .u-align-self-xs-baseline {
    align-self: baseline !important;
  }
}
@media screen and (min-width: 576px) {
  .u-flex-grow-sm-full {
    flex-grow: 1 !important;
  }
  .u-flex-sm-row {
    flex-direction: row !important;
  }
  .u-flex-sm-column {
    flex-direction: column !important;
  }
  .u-flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .u-flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .u-flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .u-flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .u-flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .u-justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .u-justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .u-justify-content-sm-center {
    justify-content: center !important;
  }
  .u-justify-content-sm-between {
    justify-content: space-between !important;
  }
  .u-justify-content-sm-around {
    justify-content: space-around !important;
  }
  .u-justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .u-align-items-sm-start {
    align-items: flex-start !important;
  }
  .u-align-items-sm-end {
    align-items: flex-end !important;
  }
  .u-align-items-sm-center {
    align-items: center !important;
  }
  .u-align-items-sm-baseline {
    align-items: baseline !important;
  }
  .u-align-items-sm-stretch {
    align-items: stretch !important;
  }
  .u-align-content-sm-start {
    align-content: flex-start !important;
  }
  .u-align-content-sm-end {
    align-content: flex-end !important;
  }
  .u-align-content-sm-center {
    align-content: center !important;
  }
  .u-align-content-sm-between {
    align-content: space-between !important;
  }
  .u-align-content-sm-around {
    align-content: space-around !important;
  }
  .u-align-content-sm-stretch {
    align-content: stretch !important;
  }
  .u-align-self-sm-auto {
    align-self: auto !important;
  }
  .u-align-self-sm-start {
    align-self: flex-start !important;
  }
  .u-align-self-sm-end {
    align-self: flex-end !important;
  }
  .u-align-self-sm-center {
    align-self: center !important;
  }
  .u-align-self-sm-baseline {
    align-self: baseline !important;
  }
}
@media screen and (min-width: 768px) {
  .u-flex-grow-md-full {
    flex-grow: 1 !important;
  }
  .u-flex-md-row {
    flex-direction: row !important;
  }
  .u-flex-md-column {
    flex-direction: column !important;
  }
  .u-flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .u-flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .u-flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .u-flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .u-flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .u-justify-content-md-start {
    justify-content: flex-start !important;
  }
  .u-justify-content-md-end {
    justify-content: flex-end !important;
  }
  .u-justify-content-md-center {
    justify-content: center !important;
  }
  .u-justify-content-md-between {
    justify-content: space-between !important;
  }
  .u-justify-content-md-around {
    justify-content: space-around !important;
  }
  .u-justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .u-align-items-md-start {
    align-items: flex-start !important;
  }
  .u-align-items-md-end {
    align-items: flex-end !important;
  }
  .u-align-items-md-center {
    align-items: center !important;
  }
  .u-align-items-md-baseline {
    align-items: baseline !important;
  }
  .u-align-items-md-stretch {
    align-items: stretch !important;
  }
  .u-align-content-md-start {
    align-content: flex-start !important;
  }
  .u-align-content-md-end {
    align-content: flex-end !important;
  }
  .u-align-content-md-center {
    align-content: center !important;
  }
  .u-align-content-md-between {
    align-content: space-between !important;
  }
  .u-align-content-md-around {
    align-content: space-around !important;
  }
  .u-align-content-md-stretch {
    align-content: stretch !important;
  }
  .u-align-self-md-auto {
    align-self: auto !important;
  }
  .u-align-self-md-start {
    align-self: flex-start !important;
  }
  .u-align-self-md-end {
    align-self: flex-end !important;
  }
  .u-align-self-md-center {
    align-self: center !important;
  }
  .u-align-self-md-baseline {
    align-self: baseline !important;
  }
}
@media screen and (min-width: 992px) {
  .u-flex-grow-lg-full {
    flex-grow: 1 !important;
  }
  .u-flex-lg-row {
    flex-direction: row !important;
  }
  .u-flex-lg-column {
    flex-direction: column !important;
  }
  .u-flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .u-flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .u-flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .u-flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .u-flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .u-justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .u-justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .u-justify-content-lg-center {
    justify-content: center !important;
  }
  .u-justify-content-lg-between {
    justify-content: space-between !important;
  }
  .u-justify-content-lg-around {
    justify-content: space-around !important;
  }
  .u-justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .u-align-items-lg-start {
    align-items: flex-start !important;
  }
  .u-align-items-lg-end {
    align-items: flex-end !important;
  }
  .u-align-items-lg-center {
    align-items: center !important;
  }
  .u-align-items-lg-baseline {
    align-items: baseline !important;
  }
  .u-align-items-lg-stretch {
    align-items: stretch !important;
  }
  .u-align-content-lg-start {
    align-content: flex-start !important;
  }
  .u-align-content-lg-end {
    align-content: flex-end !important;
  }
  .u-align-content-lg-center {
    align-content: center !important;
  }
  .u-align-content-lg-between {
    align-content: space-between !important;
  }
  .u-align-content-lg-around {
    align-content: space-around !important;
  }
  .u-align-content-lg-stretch {
    align-content: stretch !important;
  }
  .u-align-self-lg-auto {
    align-self: auto !important;
  }
  .u-align-self-lg-start {
    align-self: flex-start !important;
  }
  .u-align-self-lg-end {
    align-self: flex-end !important;
  }
  .u-align-self-lg-center {
    align-self: center !important;
  }
  .u-align-self-lg-baseline {
    align-self: baseline !important;
  }
}
@media screen and (min-width: 1200px) {
  .u-flex-grow-xl-full {
    flex-grow: 1 !important;
  }
  .u-flex-xl-row {
    flex-direction: row !important;
  }
  .u-flex-xl-column {
    flex-direction: column !important;
  }
  .u-flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .u-flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .u-flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .u-flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .u-flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .u-justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .u-justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .u-justify-content-xl-center {
    justify-content: center !important;
  }
  .u-justify-content-xl-between {
    justify-content: space-between !important;
  }
  .u-justify-content-xl-around {
    justify-content: space-around !important;
  }
  .u-justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .u-align-items-xl-start {
    align-items: flex-start !important;
  }
  .u-align-items-xl-end {
    align-items: flex-end !important;
  }
  .u-align-items-xl-center {
    align-items: center !important;
  }
  .u-align-items-xl-baseline {
    align-items: baseline !important;
  }
  .u-align-items-xl-stretch {
    align-items: stretch !important;
  }
  .u-align-content-xl-start {
    align-content: flex-start !important;
  }
  .u-align-content-xl-end {
    align-content: flex-end !important;
  }
  .u-align-content-xl-center {
    align-content: center !important;
  }
  .u-align-content-xl-between {
    align-content: space-between !important;
  }
  .u-align-content-xl-around {
    align-content: space-around !important;
  }
  .u-align-content-xl-stretch {
    align-content: stretch !important;
  }
  .u-align-self-xl-auto {
    align-self: auto !important;
  }
  .u-align-self-xl-start {
    align-self: flex-start !important;
  }
  .u-align-self-xl-end {
    align-self: flex-end !important;
  }
  .u-align-self-xl-center {
    align-self: center !important;
  }
  .u-align-self-xl-baseline {
    align-self: baseline !important;
  }
}
@media screen and (min-width: 1400px) {
  .u-flex-grow-xxl-full {
    flex-grow: 1 !important;
  }
  .u-flex-xxl-row {
    flex-direction: row !important;
  }
  .u-flex-xxl-column {
    flex-direction: column !important;
  }
  .u-flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .u-flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .u-flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .u-flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .u-flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .u-justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .u-justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .u-justify-content-xxl-center {
    justify-content: center !important;
  }
  .u-justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .u-justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .u-justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .u-align-items-xxl-start {
    align-items: flex-start !important;
  }
  .u-align-items-xxl-end {
    align-items: flex-end !important;
  }
  .u-align-items-xxl-center {
    align-items: center !important;
  }
  .u-align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .u-align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .u-align-content-xxl-start {
    align-content: flex-start !important;
  }
  .u-align-content-xxl-end {
    align-content: flex-end !important;
  }
  .u-align-content-xxl-center {
    align-content: center !important;
  }
  .u-align-content-xxl-between {
    align-content: space-between !important;
  }
  .u-align-content-xxl-around {
    align-content: space-around !important;
  }
  .u-align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .u-align-self-xxl-auto {
    align-self: auto !important;
  }
  .u-align-self-xxl-start {
    align-self: flex-start !important;
  }
  .u-align-self-xxl-end {
    align-self: flex-end !important;
  }
  .u-align-self-xxl-center {
    align-self: center !important;
  }
  .u-align-self-xxl-baseline {
    align-self: baseline !important;
  }
}
.u-flex-10 {
  flex: 0 1 10% !important;
}
.u-flex-20 {
  flex: 0 1 20% !important;
}
.u-flex-30 {
  flex: 0 1 30% !important;
}
.u-flex-40 {
  flex: 0 1 40% !important;
}
.u-flex-45 {
  flex: 0 1 45% !important;
}
.u-flex-50 {
  flex: 0 1 50% !important;
}
.u-flex-60 {
  flex: 0 1 60% !important;
}
.u-flex-70 {
  flex: 0 1 70% !important;
}
.u-flex-80 {
  flex: 0 1 80% !important;
}
.u-flex-90 {
  flex: 0 1 90% !important;
}
.u-flex-100 {
  flex: 0 1 100% !important;
}
.u-flex-auto {
  flex: 0 1 auto !important;
}
.u-flex-0 {
  flex: 0 1 0px !important;
}
.u-flex-1 {
  flex: 0 1 4px !important;
}
.u-flex-2 {
  flex: 0 1 8px !important;
}
.u-flex-3 {
  flex: 0 1 12px !important;
}
.u-flex-4 {
  flex: 0 1 16px !important;
}
.u-flex-5 {
  flex: 0 1 20px !important;
}
.u-flex-6 {
  flex: 0 1 24px !important;
}
.u-flex-7 {
  flex: 0 1 28px !important;
}
.u-flex-8 {
  flex: 0 1 32px !important;
}
.u-flex-9 {
  flex: 0 1 36px !important;
}
.u-flex-3-d {
  flex: 0 1 48px !important;
}
.u-flex-4-d {
  flex: 0 1 64px !important;
}
.u-flex-5-d {
  flex: 0 1 80px !important;
}
.u-flex-6-d {
  flex: 0 1 96px !important;
}
.u-flex-7-d {
  flex: 0 1 112px !important;
}
.u-flex-8-d {
  flex: 0 1 128px !important;
}
.u-flex-9-d {
  flex: 0 1 144px !important;
}
.f56 {
  font-size: 3.5rem;
}
.f42 {
  font-size: 2.625rem;
}
.f40 {
  font-size: 2.5rem;
}
.f32 {
  font-size: 2rem;
}
.f30 {
  font-size: 1.875rem;
}
.f28 {
  font-size: 1.75rem;
}
.f24 {
  font-size: 1.5rem;
}
.f20 {
  font-size: 1.25rem;
}
.f18 {
  font-size: 1.125rem;
}
.f16 {
  font-size: 1rem;
}
.f15 {
  font-size: 0.9375rem;
}
.f14 {
  font-size: 0.875rem;
}
.f13 {
  font-size: 0.8125rem;
}
.f12 {
  font-size: 0.75rem;
}
.f10 {
  font-size: 0.75rem;
  width: 118%;
  -webkit-transform: scale(0.82) translate(-9%, 0px);
  -moz-transform: scale(0.82) translate(-9%, 0px);
  -ms-transform: scale(0.82) translate(-9%, 0px);
  -o-transform: scale(0.82) translate(-9%, 0px);
  transform: scale(0.82) translate(-9%, 0px);
}
@media screen and (min-width: 30em) {
  .f56-s {
    font-size: 3.5rem;
  }
  .f42-s {
    font-size: 2.625rem;
  }
  .f40-s {
    font-size: 2.5rem;
  }
  .f32-s {
    font-size: 2rem;
  }
  .f30-s {
    font-size: 1.875rem;
  }
  .f28-s {
    font-size: 1.75rem;
  }
  .f24-s {
    font-size: 1.5rem;
  }
  .f20-s {
    font-size: 1.25rem;
  }
  .f18-s {
    font-size: 1.125rem;
  }
  .f16-s {
    font-size: 1rem;
  }
  .f15-s {
    font-size: 0.9375rem;
  }
  .f14-s {
    font-size: 0.875rem;
  }
  .f13-s {
    font-size: 0.8125rem;
  }
  .f12-s {
    font-size: 0.75rem;
  }
  .f10-s {
    font-size: 0.75rem;
    width: 118%;
    -webkit-transform: scale(0.82) translate(-9%, 0px);
    -moz-transform: scale(0.82) translate(-9%, 0px);
    -ms-transform: scale(0.82) translate(-9%, 0px);
    -o-transform: scale(0.82) translate(-9%, 0px);
    transform: scale(0.82) translate(-9%, 0px);
  }
}
@media screen and (min-width: 48em) {
  .f56-m {
    font-size: 3.5rem;
  }
  .f42-m {
    font-size: 2.625rem;
  }
  .f40-m {
    font-size: 2.5rem;
  }
  .f32-m {
    font-size: 2rem;
  }
  .f30-m {
    font-size: 1.875rem;
  }
  .f28-m {
    font-size: 1.75rem;
  }
  .f24-m {
    font-size: 1.5rem;
  }
  .f20-m {
    font-size: 1.25rem;
  }
  .f18-m {
    font-size: 1.125rem;
  }
  .f16-m {
    font-size: 1rem;
  }
  .f15-m {
    font-size: 0.9375rem;
  }
  .f14-m {
    font-size: 0.875rem;
  }
  .f13-m {
    font-size: 0.8125rem;
  }
  .f12-m {
    font-size: 0.75rem;
  }
  .f10-m {
    font-size: 0.75rem;
    width: 118%;
    -webkit-transform: scale(0.82) translate(-9%, 0px);
    -moz-transform: scale(0.82) translate(-9%, 0px);
    -ms-transform: scale(0.82) translate(-9%, 0px);
    -o-transform: scale(0.82) translate(-9%, 0px);
    transform: scale(0.82) translate(-9%, 0px);
  }
}
@media screen and (min-width: 65em) {
  .f56-l {
    font-size: 3.5rem;
  }
  .f42-l {
    font-size: 2.625rem;
  }
  .f40-l {
    font-size: 2.5rem;
  }
  .f32-l {
    font-size: 2rem;
  }
  .f30-l {
    font-size: 1.875rem;
  }
  .f28-l {
    font-size: 1.75rem;
  }
  .f24-l {
    font-size: 1.5rem;
  }
  .f20-l {
    font-size: 1.25rem;
  }
  .f18-l {
    font-size: 1.125rem;
  }
  .f16-l {
    font-size: 1rem;
  }
  .f15-l {
    font-size: 0.9375rem;
  }
  .f14-l {
    font-size: 0.875rem;
  }
  .f13-l {
    font-size: 0.8125rem;
  }
  .f12-l {
    font-size: 0.75rem;
  }
  .f10-l {
    font-size: 0.75rem;
    width: 118%;
    -webkit-transform: scale(0.82) translate(-9%, 0px);
    -moz-transform: scale(0.82) translate(-9%, 0px);
    -ms-transform: scale(0.82) translate(-9%, 0px);
    -o-transform: scale(0.82) translate(-9%, 0px);
    transform: scale(0.82) translate(-9%, 0px);
  }
}
@media screen and (min-width: 20em) {
  .f56-xs {
    font-size: 3.5rem;
  }
  .f42-xs {
    font-size: 2.625rem;
  }
  .f40-xs {
    font-size: 2.5rem;
  }
  .f32-xs {
    font-size: 2rem;
  }
  .f30-xs {
    font-size: 1.875rem;
  }
  .f28-xs {
    font-size: 1.75rem;
  }
  .f24-xs {
    font-size: 1.5rem;
  }
  .f20-xs {
    font-size: 1.25rem;
  }
  .f18-xs {
    font-size: 1.125rem;
  }
  .f16-xs {
    font-size: 1rem;
  }
  .f15-xs {
    font-size: 0.9375rem;
  }
  .f14-xs {
    font-size: 0.875rem;
  }
  .f13-xs {
    font-size: 0.8125rem;
  }
  .f12-xs {
    font-size: 0.75rem;
  }
  .f10-xs {
    font-size: 0.75rem;
    width: 118%;
    -webkit-transform: scale(0.82) translate(-9%, 0px);
    -moz-transform: scale(0.82) translate(-9%, 0px);
    -ms-transform: scale(0.82) translate(-9%, 0px);
    -o-transform: scale(0.82) translate(-9%, 0px);
    transform: scale(0.82) translate(-9%, 0px);
  }
}
h1 {
  font-size: 24px;
}
h2 {
  font-size: 22px;
}
h3 {
  font-size: 18px;
}
h4 {
  font-size: 16px;
}
h5 {
  font-size: 12px;
}
h6 {
  font-size: 10px;
}
.fw6 {
  font-weight: 600;
}
.bold {
  font-weight: bold;
}
.fw5 {
  font-weight: 500;
}
.fw4 {
  font-weight: 400;
}
.normal {
  font-weight: normal;
}
.fw3 {
  font-weight: 300;
}
.lh1 {
  line-height: 150%;
}
.lh2 {
  line-height: 125%;
}
.p10 {
  padding: 10% !important;
}
.pt10 {
  padding-top: 10% !important;
}
.pr10 {
  padding-right: 10% !important;
}
.pb10 {
  padding-bottom: 10% !important;
}
.pl10 {
  padding-left: 10% !important;
}
.pv10 {
  padding-top: 10% !important;
  padding-bottom: 10% !important;
}
.ph10 {
  padding-left: 10% !important;
  padding-right: 10% !important;
}
.m10 {
  margin: 10% !important;
}
.mt10 {
  margin-top: 10% !important;
}
.mr10 {
  margin-right: 10% !important;
}
.mb10 {
  margin-bottom: 10% !important;
}
.ml10 {
  margin-left: 10% !important;
}
.mv10 {
  margin-top: 10% !important;
  margin-bottom: 10% !important;
}
.mh10 {
  margin-left: 10% !important;
  margin-right: 10% !important;
}
.mh-auto {
  margin: 0 auto;
}
.p20 {
  padding: 20% !important;
}
.pt20 {
  padding-top: 20% !important;
}
.pr20 {
  padding-right: 20% !important;
}
.pb20 {
  padding-bottom: 20% !important;
}
.pl20 {
  padding-left: 20% !important;
}
.pv20 {
  padding-top: 20% !important;
  padding-bottom: 20% !important;
}
.ph20 {
  padding-left: 20% !important;
  padding-right: 20% !important;
}
.m20 {
  margin: 20% !important;
}
.mt20 {
  margin-top: 20% !important;
}
.mr20 {
  margin-right: 20% !important;
}
.mb20 {
  margin-bottom: 20% !important;
}
.ml20 {
  margin-left: 20% !important;
}
.mv20 {
  margin-top: 20% !important;
  margin-bottom: 20% !important;
}
.mh20 {
  margin-left: 20% !important;
  margin-right: 20% !important;
}
.mh-auto {
  margin: 0 auto;
}
.p30 {
  padding: 30% !important;
}
.pt30 {
  padding-top: 30% !important;
}
.pr30 {
  padding-right: 30% !important;
}
.pb30 {
  padding-bottom: 30% !important;
}
.pl30 {
  padding-left: 30% !important;
}
.pv30 {
  padding-top: 30% !important;
  padding-bottom: 30% !important;
}
.ph30 {
  padding-left: 30% !important;
  padding-right: 30% !important;
}
.m30 {
  margin: 30% !important;
}
.mt30 {
  margin-top: 30% !important;
}
.mr30 {
  margin-right: 30% !important;
}
.mb30 {
  margin-bottom: 30% !important;
}
.ml30 {
  margin-left: 30% !important;
}
.mv30 {
  margin-top: 30% !important;
  margin-bottom: 30% !important;
}
.mh30 {
  margin-left: 30% !important;
  margin-right: 30% !important;
}
.mh-auto {
  margin: 0 auto;
}
.p40 {
  padding: 40% !important;
}
.pt40 {
  padding-top: 40% !important;
}
.pr40 {
  padding-right: 40% !important;
}
.pb40 {
  padding-bottom: 40% !important;
}
.pl40 {
  padding-left: 40% !important;
}
.pv40 {
  padding-top: 40% !important;
  padding-bottom: 40% !important;
}
.ph40 {
  padding-left: 40% !important;
  padding-right: 40% !important;
}
.m40 {
  margin: 40% !important;
}
.mt40 {
  margin-top: 40% !important;
}
.mr40 {
  margin-right: 40% !important;
}
.mb40 {
  margin-bottom: 40% !important;
}
.ml40 {
  margin-left: 40% !important;
}
.mv40 {
  margin-top: 40% !important;
  margin-bottom: 40% !important;
}
.mh40 {
  margin-left: 40% !important;
  margin-right: 40% !important;
}
.mh-auto {
  margin: 0 auto;
}
.p45 {
  padding: 45% !important;
}
.pt45 {
  padding-top: 45% !important;
}
.pr45 {
  padding-right: 45% !important;
}
.pb45 {
  padding-bottom: 45% !important;
}
.pl45 {
  padding-left: 45% !important;
}
.pv45 {
  padding-top: 45% !important;
  padding-bottom: 45% !important;
}
.ph45 {
  padding-left: 45% !important;
  padding-right: 45% !important;
}
.m45 {
  margin: 45% !important;
}
.mt45 {
  margin-top: 45% !important;
}
.mr45 {
  margin-right: 45% !important;
}
.mb45 {
  margin-bottom: 45% !important;
}
.ml45 {
  margin-left: 45% !important;
}
.mv45 {
  margin-top: 45% !important;
  margin-bottom: 45% !important;
}
.mh45 {
  margin-left: 45% !important;
  margin-right: 45% !important;
}
.mh-auto {
  margin: 0 auto;
}
.p50 {
  padding: 50% !important;
}
.pt50 {
  padding-top: 50% !important;
}
.pr50 {
  padding-right: 50% !important;
}
.pb50 {
  padding-bottom: 50% !important;
}
.pl50 {
  padding-left: 50% !important;
}
.pv50 {
  padding-top: 50% !important;
  padding-bottom: 50% !important;
}
.ph50 {
  padding-left: 50% !important;
  padding-right: 50% !important;
}
.m50 {
  margin: 50% !important;
}
.mt50 {
  margin-top: 50% !important;
}
.mr50 {
  margin-right: 50% !important;
}
.mb50 {
  margin-bottom: 50% !important;
}
.ml50 {
  margin-left: 50% !important;
}
.mv50 {
  margin-top: 50% !important;
  margin-bottom: 50% !important;
}
.mh50 {
  margin-left: 50% !important;
  margin-right: 50% !important;
}
.mh-auto {
  margin: 0 auto;
}
.p60 {
  padding: 60% !important;
}
.pt60 {
  padding-top: 60% !important;
}
.pr60 {
  padding-right: 60% !important;
}
.pb60 {
  padding-bottom: 60% !important;
}
.pl60 {
  padding-left: 60% !important;
}
.pv60 {
  padding-top: 60% !important;
  padding-bottom: 60% !important;
}
.ph60 {
  padding-left: 60% !important;
  padding-right: 60% !important;
}
.m60 {
  margin: 60% !important;
}
.mt60 {
  margin-top: 60% !important;
}
.mr60 {
  margin-right: 60% !important;
}
.mb60 {
  margin-bottom: 60% !important;
}
.ml60 {
  margin-left: 60% !important;
}
.mv60 {
  margin-top: 60% !important;
  margin-bottom: 60% !important;
}
.mh60 {
  margin-left: 60% !important;
  margin-right: 60% !important;
}
.mh-auto {
  margin: 0 auto;
}
.p70 {
  padding: 70% !important;
}
.pt70 {
  padding-top: 70% !important;
}
.pr70 {
  padding-right: 70% !important;
}
.pb70 {
  padding-bottom: 70% !important;
}
.pl70 {
  padding-left: 70% !important;
}
.pv70 {
  padding-top: 70% !important;
  padding-bottom: 70% !important;
}
.ph70 {
  padding-left: 70% !important;
  padding-right: 70% !important;
}
.m70 {
  margin: 70% !important;
}
.mt70 {
  margin-top: 70% !important;
}
.mr70 {
  margin-right: 70% !important;
}
.mb70 {
  margin-bottom: 70% !important;
}
.ml70 {
  margin-left: 70% !important;
}
.mv70 {
  margin-top: 70% !important;
  margin-bottom: 70% !important;
}
.mh70 {
  margin-left: 70% !important;
  margin-right: 70% !important;
}
.mh-auto {
  margin: 0 auto;
}
.p80 {
  padding: 80% !important;
}
.pt80 {
  padding-top: 80% !important;
}
.pr80 {
  padding-right: 80% !important;
}
.pb80 {
  padding-bottom: 80% !important;
}
.pl80 {
  padding-left: 80% !important;
}
.pv80 {
  padding-top: 80% !important;
  padding-bottom: 80% !important;
}
.ph80 {
  padding-left: 80% !important;
  padding-right: 80% !important;
}
.m80 {
  margin: 80% !important;
}
.mt80 {
  margin-top: 80% !important;
}
.mr80 {
  margin-right: 80% !important;
}
.mb80 {
  margin-bottom: 80% !important;
}
.ml80 {
  margin-left: 80% !important;
}
.mv80 {
  margin-top: 80% !important;
  margin-bottom: 80% !important;
}
.mh80 {
  margin-left: 80% !important;
  margin-right: 80% !important;
}
.mh-auto {
  margin: 0 auto;
}
.p90 {
  padding: 90% !important;
}
.pt90 {
  padding-top: 90% !important;
}
.pr90 {
  padding-right: 90% !important;
}
.pb90 {
  padding-bottom: 90% !important;
}
.pl90 {
  padding-left: 90% !important;
}
.pv90 {
  padding-top: 90% !important;
  padding-bottom: 90% !important;
}
.ph90 {
  padding-left: 90% !important;
  padding-right: 90% !important;
}
.m90 {
  margin: 90% !important;
}
.mt90 {
  margin-top: 90% !important;
}
.mr90 {
  margin-right: 90% !important;
}
.mb90 {
  margin-bottom: 90% !important;
}
.ml90 {
  margin-left: 90% !important;
}
.mv90 {
  margin-top: 90% !important;
  margin-bottom: 90% !important;
}
.mh90 {
  margin-left: 90% !important;
  margin-right: 90% !important;
}
.mh-auto {
  margin: 0 auto;
}
.p100 {
  padding: 100% !important;
}
.pt100 {
  padding-top: 100% !important;
}
.pr100 {
  padding-right: 100% !important;
}
.pb100 {
  padding-bottom: 100% !important;
}
.pl100 {
  padding-left: 100% !important;
}
.pv100 {
  padding-top: 100% !important;
  padding-bottom: 100% !important;
}
.ph100 {
  padding-left: 100% !important;
  padding-right: 100% !important;
}
.m100 {
  margin: 100% !important;
}
.mt100 {
  margin-top: 100% !important;
}
.mr100 {
  margin-right: 100% !important;
}
.mb100 {
  margin-bottom: 100% !important;
}
.ml100 {
  margin-left: 100% !important;
}
.mv100 {
  margin-top: 100% !important;
  margin-bottom: 100% !important;
}
.mh100 {
  margin-left: 100% !important;
  margin-right: 100% !important;
}
.mh-auto {
  margin: 0 auto;
}
.pauto {
  padding: auto !important;
}
.ptauto {
  padding-top: auto !important;
}
.prauto {
  padding-right: auto !important;
}
.pbauto {
  padding-bottom: auto !important;
}
.plauto {
  padding-left: auto !important;
}
.pvauto {
  padding-top: auto !important;
  padding-bottom: auto !important;
}
.phauto {
  padding-left: auto !important;
  padding-right: auto !important;
}
.mauto {
  margin: auto !important;
}
.mtauto {
  margin-top: auto !important;
}
.mrauto {
  margin-right: auto !important;
}
.mbauto {
  margin-bottom: auto !important;
}
.mlauto {
  margin-left: auto !important;
}
.mvauto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.mhauto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.mh-auto {
  margin: 0 auto;
}
.p0 {
  padding: 0px !important;
}
.pt0 {
  padding-top: 0px !important;
}
.pr0 {
  padding-right: 0px !important;
}
.pb0 {
  padding-bottom: 0px !important;
}
.pl0 {
  padding-left: 0px !important;
}
.pv0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.ph0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.m0 {
  margin: 0px !important;
}
.mt0 {
  margin-top: 0px !important;
}
.mr0 {
  margin-right: 0px !important;
}
.mb0 {
  margin-bottom: 0px !important;
}
.ml0 {
  margin-left: 0px !important;
}
.mv0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.mh0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.mh-auto {
  margin: 0 auto;
}
.p1 {
  padding: 4px !important;
}
.pt1 {
  padding-top: 4px !important;
}
.pr1 {
  padding-right: 4px !important;
}
.pb1 {
  padding-bottom: 4px !important;
}
.pl1 {
  padding-left: 4px !important;
}
.pv1 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
.ph1 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}
.m1 {
  margin: 4px !important;
}
.mt1 {
  margin-top: 4px !important;
}
.mr1 {
  margin-right: 4px !important;
}
.mb1 {
  margin-bottom: 4px !important;
}
.ml1 {
  margin-left: 4px !important;
}
.mv1 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}
.mh1 {
  margin-left: 4px !important;
  margin-right: 4px !important;
}
.mh-auto {
  margin: 0 auto;
}
.p2 {
  padding: 8px !important;
}
.pt2 {
  padding-top: 8px !important;
}
.pr2 {
  padding-right: 8px !important;
}
.pb2 {
  padding-bottom: 8px !important;
}
.pl2 {
  padding-left: 8px !important;
}
.pv2 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.ph2 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.m2 {
  margin: 8px !important;
}
.mt2 {
  margin-top: 8px !important;
}
.mr2 {
  margin-right: 8px !important;
}
.mb2 {
  margin-bottom: 8px !important;
}
.ml2 {
  margin-left: 8px !important;
}
.mv2 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}
.mh2 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}
.mh-auto {
  margin: 0 auto;
}
.p3 {
  padding: 12px !important;
}
.pt3 {
  padding-top: 12px !important;
}
.pr3 {
  padding-right: 12px !important;
}
.pb3 {
  padding-bottom: 12px !important;
}
.pl3 {
  padding-left: 12px !important;
}
.pv3 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
.ph3 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.m3 {
  margin: 12px !important;
}
.mt3 {
  margin-top: 12px !important;
}
.mr3 {
  margin-right: 12px !important;
}
.mb3 {
  margin-bottom: 12px !important;
}
.ml3 {
  margin-left: 12px !important;
}
.mv3 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}
.mh3 {
  margin-left: 12px !important;
  margin-right: 12px !important;
}
.mh-auto {
  margin: 0 auto;
}
.p4 {
  padding: 16px !important;
}
.pt4 {
  padding-top: 16px !important;
}
.pr4 {
  padding-right: 16px !important;
}
.pb4 {
  padding-bottom: 16px !important;
}
.pl4 {
  padding-left: 16px !important;
}
.pv4 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}
.ph4 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.m4 {
  margin: 16px !important;
}
.mt4 {
  margin-top: 16px !important;
}
.mr4 {
  margin-right: 16px !important;
}
.mb4 {
  margin-bottom: 16px !important;
}
.ml4 {
  margin-left: 16px !important;
}
.mv4 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}
.mh4 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}
.mh-auto {
  margin: 0 auto;
}
.p5 {
  padding: 20px !important;
}
.pt5 {
  padding-top: 20px !important;
}
.pr5 {
  padding-right: 20px !important;
}
.pb5 {
  padding-bottom: 20px !important;
}
.pl5 {
  padding-left: 20px !important;
}
.pv5 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.ph5 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.m5 {
  margin: 20px !important;
}
.mt5 {
  margin-top: 20px !important;
}
.mr5 {
  margin-right: 20px !important;
}
.mb5 {
  margin-bottom: 20px !important;
}
.ml5 {
  margin-left: 20px !important;
}
.mv5 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.mh5 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}
.mh-auto {
  margin: 0 auto;
}
.p6 {
  padding: 24px !important;
}
.pt6 {
  padding-top: 24px !important;
}
.pr6 {
  padding-right: 24px !important;
}
.pb6 {
  padding-bottom: 24px !important;
}
.pl6 {
  padding-left: 24px !important;
}
.pv6 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}
.ph6 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}
.m6 {
  margin: 24px !important;
}
.mt6 {
  margin-top: 24px !important;
}
.mr6 {
  margin-right: 24px !important;
}
.mb6 {
  margin-bottom: 24px !important;
}
.ml6 {
  margin-left: 24px !important;
}
.mv6 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}
.mh6 {
  margin-left: 24px !important;
  margin-right: 24px !important;
}
.mh-auto {
  margin: 0 auto;
}
.p7 {
  padding: 28px !important;
}
.pt7 {
  padding-top: 28px !important;
}
.pr7 {
  padding-right: 28px !important;
}
.pb7 {
  padding-bottom: 28px !important;
}
.pl7 {
  padding-left: 28px !important;
}
.pv7 {
  padding-top: 28px !important;
  padding-bottom: 28px !important;
}
.ph7 {
  padding-left: 28px !important;
  padding-right: 28px !important;
}
.m7 {
  margin: 28px !important;
}
.mt7 {
  margin-top: 28px !important;
}
.mr7 {
  margin-right: 28px !important;
}
.mb7 {
  margin-bottom: 28px !important;
}
.ml7 {
  margin-left: 28px !important;
}
.mv7 {
  margin-top: 28px !important;
  margin-bottom: 28px !important;
}
.mh7 {
  margin-left: 28px !important;
  margin-right: 28px !important;
}
.mh-auto {
  margin: 0 auto;
}
.p8 {
  padding: 32px !important;
}
.pt8 {
  padding-top: 32px !important;
}
.pr8 {
  padding-right: 32px !important;
}
.pb8 {
  padding-bottom: 32px !important;
}
.pl8 {
  padding-left: 32px !important;
}
.pv8 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}
.ph8 {
  padding-left: 32px !important;
  padding-right: 32px !important;
}
.m8 {
  margin: 32px !important;
}
.mt8 {
  margin-top: 32px !important;
}
.mr8 {
  margin-right: 32px !important;
}
.mb8 {
  margin-bottom: 32px !important;
}
.ml8 {
  margin-left: 32px !important;
}
.mv8 {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}
.mh8 {
  margin-left: 32px !important;
  margin-right: 32px !important;
}
.mh-auto {
  margin: 0 auto;
}
.p9 {
  padding: 36px !important;
}
.pt9 {
  padding-top: 36px !important;
}
.pr9 {
  padding-right: 36px !important;
}
.pb9 {
  padding-bottom: 36px !important;
}
.pl9 {
  padding-left: 36px !important;
}
.pv9 {
  padding-top: 36px !important;
  padding-bottom: 36px !important;
}
.ph9 {
  padding-left: 36px !important;
  padding-right: 36px !important;
}
.m9 {
  margin: 36px !important;
}
.mt9 {
  margin-top: 36px !important;
}
.mr9 {
  margin-right: 36px !important;
}
.mb9 {
  margin-bottom: 36px !important;
}
.ml9 {
  margin-left: 36px !important;
}
.mv9 {
  margin-top: 36px !important;
  margin-bottom: 36px !important;
}
.mh9 {
  margin-left: 36px !important;
  margin-right: 36px !important;
}
.mh-auto {
  margin: 0 auto;
}
.p3-d {
  padding: 48px !important;
}
.pt3-d {
  padding-top: 48px !important;
}
.pr3-d {
  padding-right: 48px !important;
}
.pb3-d {
  padding-bottom: 48px !important;
}
.pl3-d {
  padding-left: 48px !important;
}
.pv3-d {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}
.ph3-d {
  padding-left: 48px !important;
  padding-right: 48px !important;
}
.m3-d {
  margin: 48px !important;
}
.mt3-d {
  margin-top: 48px !important;
}
.mr3-d {
  margin-right: 48px !important;
}
.mb3-d {
  margin-bottom: 48px !important;
}
.ml3-d {
  margin-left: 48px !important;
}
.mv3-d {
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}
.mh3-d {
  margin-left: 48px !important;
  margin-right: 48px !important;
}
.mh-auto {
  margin: 0 auto;
}
.p4-d {
  padding: 64px !important;
}
.pt4-d {
  padding-top: 64px !important;
}
.pr4-d {
  padding-right: 64px !important;
}
.pb4-d {
  padding-bottom: 64px !important;
}
.pl4-d {
  padding-left: 64px !important;
}
.pv4-d {
  padding-top: 64px !important;
  padding-bottom: 64px !important;
}
.ph4-d {
  padding-left: 64px !important;
  padding-right: 64px !important;
}
.m4-d {
  margin: 64px !important;
}
.mt4-d {
  margin-top: 64px !important;
}
.mr4-d {
  margin-right: 64px !important;
}
.mb4-d {
  margin-bottom: 64px !important;
}
.ml4-d {
  margin-left: 64px !important;
}
.mv4-d {
  margin-top: 64px !important;
  margin-bottom: 64px !important;
}
.mh4-d {
  margin-left: 64px !important;
  margin-right: 64px !important;
}
.mh-auto {
  margin: 0 auto;
}
.p5-d {
  padding: 80px !important;
}
.pt5-d {
  padding-top: 80px !important;
}
.pr5-d {
  padding-right: 80px !important;
}
.pb5-d {
  padding-bottom: 80px !important;
}
.pl5-d {
  padding-left: 80px !important;
}
.pv5-d {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}
.ph5-d {
  padding-left: 80px !important;
  padding-right: 80px !important;
}
.m5-d {
  margin: 80px !important;
}
.mt5-d {
  margin-top: 80px !important;
}
.mr5-d {
  margin-right: 80px !important;
}
.mb5-d {
  margin-bottom: 80px !important;
}
.ml5-d {
  margin-left: 80px !important;
}
.mv5-d {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}
.mh5-d {
  margin-left: 80px !important;
  margin-right: 80px !important;
}
.mh-auto {
  margin: 0 auto;
}
.p6-d {
  padding: 96px !important;
}
.pt6-d {
  padding-top: 96px !important;
}
.pr6-d {
  padding-right: 96px !important;
}
.pb6-d {
  padding-bottom: 96px !important;
}
.pl6-d {
  padding-left: 96px !important;
}
.pv6-d {
  padding-top: 96px !important;
  padding-bottom: 96px !important;
}
.ph6-d {
  padding-left: 96px !important;
  padding-right: 96px !important;
}
.m6-d {
  margin: 96px !important;
}
.mt6-d {
  margin-top: 96px !important;
}
.mr6-d {
  margin-right: 96px !important;
}
.mb6-d {
  margin-bottom: 96px !important;
}
.ml6-d {
  margin-left: 96px !important;
}
.mv6-d {
  margin-top: 96px !important;
  margin-bottom: 96px !important;
}
.mh6-d {
  margin-left: 96px !important;
  margin-right: 96px !important;
}
.mh-auto {
  margin: 0 auto;
}
.p7-d {
  padding: 112px !important;
}
.pt7-d {
  padding-top: 112px !important;
}
.pr7-d {
  padding-right: 112px !important;
}
.pb7-d {
  padding-bottom: 112px !important;
}
.pl7-d {
  padding-left: 112px !important;
}
.pv7-d {
  padding-top: 112px !important;
  padding-bottom: 112px !important;
}
.ph7-d {
  padding-left: 112px !important;
  padding-right: 112px !important;
}
.m7-d {
  margin: 112px !important;
}
.mt7-d {
  margin-top: 112px !important;
}
.mr7-d {
  margin-right: 112px !important;
}
.mb7-d {
  margin-bottom: 112px !important;
}
.ml7-d {
  margin-left: 112px !important;
}
.mv7-d {
  margin-top: 112px !important;
  margin-bottom: 112px !important;
}
.mh7-d {
  margin-left: 112px !important;
  margin-right: 112px !important;
}
.mh-auto {
  margin: 0 auto;
}
.p8-d {
  padding: 128px !important;
}
.pt8-d {
  padding-top: 128px !important;
}
.pr8-d {
  padding-right: 128px !important;
}
.pb8-d {
  padding-bottom: 128px !important;
}
.pl8-d {
  padding-left: 128px !important;
}
.pv8-d {
  padding-top: 128px !important;
  padding-bottom: 128px !important;
}
.ph8-d {
  padding-left: 128px !important;
  padding-right: 128px !important;
}
.m8-d {
  margin: 128px !important;
}
.mt8-d {
  margin-top: 128px !important;
}
.mr8-d {
  margin-right: 128px !important;
}
.mb8-d {
  margin-bottom: 128px !important;
}
.ml8-d {
  margin-left: 128px !important;
}
.mv8-d {
  margin-top: 128px !important;
  margin-bottom: 128px !important;
}
.mh8-d {
  margin-left: 128px !important;
  margin-right: 128px !important;
}
.mh-auto {
  margin: 0 auto;
}
.p9-d {
  padding: 144px !important;
}
.pt9-d {
  padding-top: 144px !important;
}
.pr9-d {
  padding-right: 144px !important;
}
.pb9-d {
  padding-bottom: 144px !important;
}
.pl9-d {
  padding-left: 144px !important;
}
.pv9-d {
  padding-top: 144px !important;
  padding-bottom: 144px !important;
}
.ph9-d {
  padding-left: 144px !important;
  padding-right: 144px !important;
}
.m9-d {
  margin: 144px !important;
}
.mt9-d {
  margin-top: 144px !important;
}
.mr9-d {
  margin-right: 144px !important;
}
.mb9-d {
  margin-bottom: 144px !important;
}
.ml9-d {
  margin-left: 144px !important;
}
.mv9-d {
  margin-top: 144px !important;
  margin-bottom: 144px !important;
}
.mh9-d {
  margin-left: 144px !important;
  margin-right: 144px !important;
}
.mh-auto {
  margin: 0 auto;
}
@media (min-width: 30em) {
  .p10-s {
    padding: 10% !important;
  }
  .pt10-s {
    padding-top: 10% !important;
  }
  .pr10-s {
    padding-right: 10% !important;
  }
  .pb10-s {
    padding-bottom: 10% !important;
  }
  .pl10-s {
    padding-left: 10% !important;
  }
  .pv10-s {
    padding-top: 10% !important;
    padding-bottom: 10% !important;
  }
  .ph10-s {
    padding-left: 10% !important;
    padding-right: 10% !important;
  }
  .m10-s {
    margin: 10% !important;
  }
  .mt10-s {
    margin-top: 10% !important;
  }
  .mr10-s {
    margin-right: 10% !important;
  }
  .mb10-s {
    margin-bottom: 10% !important;
  }
  .ml10-s {
    margin-left: 10% !important;
  }
  .mv10-s {
    margin-top: 10% !important;
    margin-bottom: 10% !important;
  }
  .mh10-s {
    margin-left: 10% !important;
    margin-right: 10% !important;
  }
  .p20-s {
    padding: 20% !important;
  }
  .pt20-s {
    padding-top: 20% !important;
  }
  .pr20-s {
    padding-right: 20% !important;
  }
  .pb20-s {
    padding-bottom: 20% !important;
  }
  .pl20-s {
    padding-left: 20% !important;
  }
  .pv20-s {
    padding-top: 20% !important;
    padding-bottom: 20% !important;
  }
  .ph20-s {
    padding-left: 20% !important;
    padding-right: 20% !important;
  }
  .m20-s {
    margin: 20% !important;
  }
  .mt20-s {
    margin-top: 20% !important;
  }
  .mr20-s {
    margin-right: 20% !important;
  }
  .mb20-s {
    margin-bottom: 20% !important;
  }
  .ml20-s {
    margin-left: 20% !important;
  }
  .mv20-s {
    margin-top: 20% !important;
    margin-bottom: 20% !important;
  }
  .mh20-s {
    margin-left: 20% !important;
    margin-right: 20% !important;
  }
  .p30-s {
    padding: 30% !important;
  }
  .pt30-s {
    padding-top: 30% !important;
  }
  .pr30-s {
    padding-right: 30% !important;
  }
  .pb30-s {
    padding-bottom: 30% !important;
  }
  .pl30-s {
    padding-left: 30% !important;
  }
  .pv30-s {
    padding-top: 30% !important;
    padding-bottom: 30% !important;
  }
  .ph30-s {
    padding-left: 30% !important;
    padding-right: 30% !important;
  }
  .m30-s {
    margin: 30% !important;
  }
  .mt30-s {
    margin-top: 30% !important;
  }
  .mr30-s {
    margin-right: 30% !important;
  }
  .mb30-s {
    margin-bottom: 30% !important;
  }
  .ml30-s {
    margin-left: 30% !important;
  }
  .mv30-s {
    margin-top: 30% !important;
    margin-bottom: 30% !important;
  }
  .mh30-s {
    margin-left: 30% !important;
    margin-right: 30% !important;
  }
  .p40-s {
    padding: 40% !important;
  }
  .pt40-s {
    padding-top: 40% !important;
  }
  .pr40-s {
    padding-right: 40% !important;
  }
  .pb40-s {
    padding-bottom: 40% !important;
  }
  .pl40-s {
    padding-left: 40% !important;
  }
  .pv40-s {
    padding-top: 40% !important;
    padding-bottom: 40% !important;
  }
  .ph40-s {
    padding-left: 40% !important;
    padding-right: 40% !important;
  }
  .m40-s {
    margin: 40% !important;
  }
  .mt40-s {
    margin-top: 40% !important;
  }
  .mr40-s {
    margin-right: 40% !important;
  }
  .mb40-s {
    margin-bottom: 40% !important;
  }
  .ml40-s {
    margin-left: 40% !important;
  }
  .mv40-s {
    margin-top: 40% !important;
    margin-bottom: 40% !important;
  }
  .mh40-s {
    margin-left: 40% !important;
    margin-right: 40% !important;
  }
  .p45-s {
    padding: 45% !important;
  }
  .pt45-s {
    padding-top: 45% !important;
  }
  .pr45-s {
    padding-right: 45% !important;
  }
  .pb45-s {
    padding-bottom: 45% !important;
  }
  .pl45-s {
    padding-left: 45% !important;
  }
  .pv45-s {
    padding-top: 45% !important;
    padding-bottom: 45% !important;
  }
  .ph45-s {
    padding-left: 45% !important;
    padding-right: 45% !important;
  }
  .m45-s {
    margin: 45% !important;
  }
  .mt45-s {
    margin-top: 45% !important;
  }
  .mr45-s {
    margin-right: 45% !important;
  }
  .mb45-s {
    margin-bottom: 45% !important;
  }
  .ml45-s {
    margin-left: 45% !important;
  }
  .mv45-s {
    margin-top: 45% !important;
    margin-bottom: 45% !important;
  }
  .mh45-s {
    margin-left: 45% !important;
    margin-right: 45% !important;
  }
  .p50-s {
    padding: 50% !important;
  }
  .pt50-s {
    padding-top: 50% !important;
  }
  .pr50-s {
    padding-right: 50% !important;
  }
  .pb50-s {
    padding-bottom: 50% !important;
  }
  .pl50-s {
    padding-left: 50% !important;
  }
  .pv50-s {
    padding-top: 50% !important;
    padding-bottom: 50% !important;
  }
  .ph50-s {
    padding-left: 50% !important;
    padding-right: 50% !important;
  }
  .m50-s {
    margin: 50% !important;
  }
  .mt50-s {
    margin-top: 50% !important;
  }
  .mr50-s {
    margin-right: 50% !important;
  }
  .mb50-s {
    margin-bottom: 50% !important;
  }
  .ml50-s {
    margin-left: 50% !important;
  }
  .mv50-s {
    margin-top: 50% !important;
    margin-bottom: 50% !important;
  }
  .mh50-s {
    margin-left: 50% !important;
    margin-right: 50% !important;
  }
  .p60-s {
    padding: 60% !important;
  }
  .pt60-s {
    padding-top: 60% !important;
  }
  .pr60-s {
    padding-right: 60% !important;
  }
  .pb60-s {
    padding-bottom: 60% !important;
  }
  .pl60-s {
    padding-left: 60% !important;
  }
  .pv60-s {
    padding-top: 60% !important;
    padding-bottom: 60% !important;
  }
  .ph60-s {
    padding-left: 60% !important;
    padding-right: 60% !important;
  }
  .m60-s {
    margin: 60% !important;
  }
  .mt60-s {
    margin-top: 60% !important;
  }
  .mr60-s {
    margin-right: 60% !important;
  }
  .mb60-s {
    margin-bottom: 60% !important;
  }
  .ml60-s {
    margin-left: 60% !important;
  }
  .mv60-s {
    margin-top: 60% !important;
    margin-bottom: 60% !important;
  }
  .mh60-s {
    margin-left: 60% !important;
    margin-right: 60% !important;
  }
  .p70-s {
    padding: 70% !important;
  }
  .pt70-s {
    padding-top: 70% !important;
  }
  .pr70-s {
    padding-right: 70% !important;
  }
  .pb70-s {
    padding-bottom: 70% !important;
  }
  .pl70-s {
    padding-left: 70% !important;
  }
  .pv70-s {
    padding-top: 70% !important;
    padding-bottom: 70% !important;
  }
  .ph70-s {
    padding-left: 70% !important;
    padding-right: 70% !important;
  }
  .m70-s {
    margin: 70% !important;
  }
  .mt70-s {
    margin-top: 70% !important;
  }
  .mr70-s {
    margin-right: 70% !important;
  }
  .mb70-s {
    margin-bottom: 70% !important;
  }
  .ml70-s {
    margin-left: 70% !important;
  }
  .mv70-s {
    margin-top: 70% !important;
    margin-bottom: 70% !important;
  }
  .mh70-s {
    margin-left: 70% !important;
    margin-right: 70% !important;
  }
  .p80-s {
    padding: 80% !important;
  }
  .pt80-s {
    padding-top: 80% !important;
  }
  .pr80-s {
    padding-right: 80% !important;
  }
  .pb80-s {
    padding-bottom: 80% !important;
  }
  .pl80-s {
    padding-left: 80% !important;
  }
  .pv80-s {
    padding-top: 80% !important;
    padding-bottom: 80% !important;
  }
  .ph80-s {
    padding-left: 80% !important;
    padding-right: 80% !important;
  }
  .m80-s {
    margin: 80% !important;
  }
  .mt80-s {
    margin-top: 80% !important;
  }
  .mr80-s {
    margin-right: 80% !important;
  }
  .mb80-s {
    margin-bottom: 80% !important;
  }
  .ml80-s {
    margin-left: 80% !important;
  }
  .mv80-s {
    margin-top: 80% !important;
    margin-bottom: 80% !important;
  }
  .mh80-s {
    margin-left: 80% !important;
    margin-right: 80% !important;
  }
  .p90-s {
    padding: 90% !important;
  }
  .pt90-s {
    padding-top: 90% !important;
  }
  .pr90-s {
    padding-right: 90% !important;
  }
  .pb90-s {
    padding-bottom: 90% !important;
  }
  .pl90-s {
    padding-left: 90% !important;
  }
  .pv90-s {
    padding-top: 90% !important;
    padding-bottom: 90% !important;
  }
  .ph90-s {
    padding-left: 90% !important;
    padding-right: 90% !important;
  }
  .m90-s {
    margin: 90% !important;
  }
  .mt90-s {
    margin-top: 90% !important;
  }
  .mr90-s {
    margin-right: 90% !important;
  }
  .mb90-s {
    margin-bottom: 90% !important;
  }
  .ml90-s {
    margin-left: 90% !important;
  }
  .mv90-s {
    margin-top: 90% !important;
    margin-bottom: 90% !important;
  }
  .mh90-s {
    margin-left: 90% !important;
    margin-right: 90% !important;
  }
  .p100-s {
    padding: 100% !important;
  }
  .pt100-s {
    padding-top: 100% !important;
  }
  .pr100-s {
    padding-right: 100% !important;
  }
  .pb100-s {
    padding-bottom: 100% !important;
  }
  .pl100-s {
    padding-left: 100% !important;
  }
  .pv100-s {
    padding-top: 100% !important;
    padding-bottom: 100% !important;
  }
  .ph100-s {
    padding-left: 100% !important;
    padding-right: 100% !important;
  }
  .m100-s {
    margin: 100% !important;
  }
  .mt100-s {
    margin-top: 100% !important;
  }
  .mr100-s {
    margin-right: 100% !important;
  }
  .mb100-s {
    margin-bottom: 100% !important;
  }
  .ml100-s {
    margin-left: 100% !important;
  }
  .mv100-s {
    margin-top: 100% !important;
    margin-bottom: 100% !important;
  }
  .mh100-s {
    margin-left: 100% !important;
    margin-right: 100% !important;
  }
  .pauto-s {
    padding: auto !important;
  }
  .ptauto-s {
    padding-top: auto !important;
  }
  .prauto-s {
    padding-right: auto !important;
  }
  .pbauto-s {
    padding-bottom: auto !important;
  }
  .plauto-s {
    padding-left: auto !important;
  }
  .pvauto-s {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
  .phauto-s {
    padding-left: auto !important;
    padding-right: auto !important;
  }
  .mauto-s {
    margin: auto !important;
  }
  .mtauto-s {
    margin-top: auto !important;
  }
  .mrauto-s {
    margin-right: auto !important;
  }
  .mbauto-s {
    margin-bottom: auto !important;
  }
  .mlauto-s {
    margin-left: auto !important;
  }
  .mvauto-s {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mhauto-s {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .p0-s {
    padding: 0px !important;
  }
  .pt0-s {
    padding-top: 0px !important;
  }
  .pr0-s {
    padding-right: 0px !important;
  }
  .pb0-s {
    padding-bottom: 0px !important;
  }
  .pl0-s {
    padding-left: 0px !important;
  }
  .pv0-s {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .ph0-s {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .m0-s {
    margin: 0px !important;
  }
  .mt0-s {
    margin-top: 0px !important;
  }
  .mr0-s {
    margin-right: 0px !important;
  }
  .mb0-s {
    margin-bottom: 0px !important;
  }
  .ml0-s {
    margin-left: 0px !important;
  }
  .mv0-s {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .mh0-s {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .p1-s {
    padding: 4px !important;
  }
  .pt1-s {
    padding-top: 4px !important;
  }
  .pr1-s {
    padding-right: 4px !important;
  }
  .pb1-s {
    padding-bottom: 4px !important;
  }
  .pl1-s {
    padding-left: 4px !important;
  }
  .pv1-s {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
  .ph1-s {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
  .m1-s {
    margin: 4px !important;
  }
  .mt1-s {
    margin-top: 4px !important;
  }
  .mr1-s {
    margin-right: 4px !important;
  }
  .mb1-s {
    margin-bottom: 4px !important;
  }
  .ml1-s {
    margin-left: 4px !important;
  }
  .mv1-s {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }
  .mh1-s {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }
  .p2-s {
    padding: 8px !important;
  }
  .pt2-s {
    padding-top: 8px !important;
  }
  .pr2-s {
    padding-right: 8px !important;
  }
  .pb2-s {
    padding-bottom: 8px !important;
  }
  .pl2-s {
    padding-left: 8px !important;
  }
  .pv2-s {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .ph2-s {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .m2-s {
    margin: 8px !important;
  }
  .mt2-s {
    margin-top: 8px !important;
  }
  .mr2-s {
    margin-right: 8px !important;
  }
  .mb2-s {
    margin-bottom: 8px !important;
  }
  .ml2-s {
    margin-left: 8px !important;
  }
  .mv2-s {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .mh2-s {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
  .p3-s {
    padding: 12px !important;
  }
  .pt3-s {
    padding-top: 12px !important;
  }
  .pr3-s {
    padding-right: 12px !important;
  }
  .pb3-s {
    padding-bottom: 12px !important;
  }
  .pl3-s {
    padding-left: 12px !important;
  }
  .pv3-s {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .ph3-s {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .m3-s {
    margin: 12px !important;
  }
  .mt3-s {
    margin-top: 12px !important;
  }
  .mr3-s {
    margin-right: 12px !important;
  }
  .mb3-s {
    margin-bottom: 12px !important;
  }
  .ml3-s {
    margin-left: 12px !important;
  }
  .mv3-s {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .mh3-s {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }
  .p4-s {
    padding: 16px !important;
  }
  .pt4-s {
    padding-top: 16px !important;
  }
  .pr4-s {
    padding-right: 16px !important;
  }
  .pb4-s {
    padding-bottom: 16px !important;
  }
  .pl4-s {
    padding-left: 16px !important;
  }
  .pv4-s {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  .ph4-s {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .m4-s {
    margin: 16px !important;
  }
  .mt4-s {
    margin-top: 16px !important;
  }
  .mr4-s {
    margin-right: 16px !important;
  }
  .mb4-s {
    margin-bottom: 16px !important;
  }
  .ml4-s {
    margin-left: 16px !important;
  }
  .mv4-s {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  .mh4-s {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }
  .p5-s {
    padding: 20px !important;
  }
  .pt5-s {
    padding-top: 20px !important;
  }
  .pr5-s {
    padding-right: 20px !important;
  }
  .pb5-s {
    padding-bottom: 20px !important;
  }
  .pl5-s {
    padding-left: 20px !important;
  }
  .pv5-s {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .ph5-s {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .m5-s {
    margin: 20px !important;
  }
  .mt5-s {
    margin-top: 20px !important;
  }
  .mr5-s {
    margin-right: 20px !important;
  }
  .mb5-s {
    margin-bottom: 20px !important;
  }
  .ml5-s {
    margin-left: 20px !important;
  }
  .mv5-s {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .mh5-s {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .p6-s {
    padding: 24px !important;
  }
  .pt6-s {
    padding-top: 24px !important;
  }
  .pr6-s {
    padding-right: 24px !important;
  }
  .pb6-s {
    padding-bottom: 24px !important;
  }
  .pl6-s {
    padding-left: 24px !important;
  }
  .pv6-s {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
  .ph6-s {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .m6-s {
    margin: 24px !important;
  }
  .mt6-s {
    margin-top: 24px !important;
  }
  .mr6-s {
    margin-right: 24px !important;
  }
  .mb6-s {
    margin-bottom: 24px !important;
  }
  .ml6-s {
    margin-left: 24px !important;
  }
  .mv6-s {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }
  .mh6-s {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }
  .p7-s {
    padding: 28px !important;
  }
  .pt7-s {
    padding-top: 28px !important;
  }
  .pr7-s {
    padding-right: 28px !important;
  }
  .pb7-s {
    padding-bottom: 28px !important;
  }
  .pl7-s {
    padding-left: 28px !important;
  }
  .pv7-s {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }
  .ph7-s {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }
  .m7-s {
    margin: 28px !important;
  }
  .mt7-s {
    margin-top: 28px !important;
  }
  .mr7-s {
    margin-right: 28px !important;
  }
  .mb7-s {
    margin-bottom: 28px !important;
  }
  .ml7-s {
    margin-left: 28px !important;
  }
  .mv7-s {
    margin-top: 28px !important;
    margin-bottom: 28px !important;
  }
  .mh7-s {
    margin-left: 28px !important;
    margin-right: 28px !important;
  }
  .p8-s {
    padding: 32px !important;
  }
  .pt8-s {
    padding-top: 32px !important;
  }
  .pr8-s {
    padding-right: 32px !important;
  }
  .pb8-s {
    padding-bottom: 32px !important;
  }
  .pl8-s {
    padding-left: 32px !important;
  }
  .pv8-s {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }
  .ph8-s {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }
  .m8-s {
    margin: 32px !important;
  }
  .mt8-s {
    margin-top: 32px !important;
  }
  .mr8-s {
    margin-right: 32px !important;
  }
  .mb8-s {
    margin-bottom: 32px !important;
  }
  .ml8-s {
    margin-left: 32px !important;
  }
  .mv8-s {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }
  .mh8-s {
    margin-left: 32px !important;
    margin-right: 32px !important;
  }
  .p9-s {
    padding: 36px !important;
  }
  .pt9-s {
    padding-top: 36px !important;
  }
  .pr9-s {
    padding-right: 36px !important;
  }
  .pb9-s {
    padding-bottom: 36px !important;
  }
  .pl9-s {
    padding-left: 36px !important;
  }
  .pv9-s {
    padding-top: 36px !important;
    padding-bottom: 36px !important;
  }
  .ph9-s {
    padding-left: 36px !important;
    padding-right: 36px !important;
  }
  .m9-s {
    margin: 36px !important;
  }
  .mt9-s {
    margin-top: 36px !important;
  }
  .mr9-s {
    margin-right: 36px !important;
  }
  .mb9-s {
    margin-bottom: 36px !important;
  }
  .ml9-s {
    margin-left: 36px !important;
  }
  .mv9-s {
    margin-top: 36px !important;
    margin-bottom: 36px !important;
  }
  .mh9-s {
    margin-left: 36px !important;
    margin-right: 36px !important;
  }
  .p3-d-s {
    padding: 48px !important;
  }
  .pt3-d-s {
    padding-top: 48px !important;
  }
  .pr3-d-s {
    padding-right: 48px !important;
  }
  .pb3-d-s {
    padding-bottom: 48px !important;
  }
  .pl3-d-s {
    padding-left: 48px !important;
  }
  .pv3-d-s {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }
  .ph3-d-s {
    padding-left: 48px !important;
    padding-right: 48px !important;
  }
  .m3-d-s {
    margin: 48px !important;
  }
  .mt3-d-s {
    margin-top: 48px !important;
  }
  .mr3-d-s {
    margin-right: 48px !important;
  }
  .mb3-d-s {
    margin-bottom: 48px !important;
  }
  .ml3-d-s {
    margin-left: 48px !important;
  }
  .mv3-d-s {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }
  .mh3-d-s {
    margin-left: 48px !important;
    margin-right: 48px !important;
  }
  .p4-d-s {
    padding: 64px !important;
  }
  .pt4-d-s {
    padding-top: 64px !important;
  }
  .pr4-d-s {
    padding-right: 64px !important;
  }
  .pb4-d-s {
    padding-bottom: 64px !important;
  }
  .pl4-d-s {
    padding-left: 64px !important;
  }
  .pv4-d-s {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }
  .ph4-d-s {
    padding-left: 64px !important;
    padding-right: 64px !important;
  }
  .m4-d-s {
    margin: 64px !important;
  }
  .mt4-d-s {
    margin-top: 64px !important;
  }
  .mr4-d-s {
    margin-right: 64px !important;
  }
  .mb4-d-s {
    margin-bottom: 64px !important;
  }
  .ml4-d-s {
    margin-left: 64px !important;
  }
  .mv4-d-s {
    margin-top: 64px !important;
    margin-bottom: 64px !important;
  }
  .mh4-d-s {
    margin-left: 64px !important;
    margin-right: 64px !important;
  }
  .p5-d-s {
    padding: 80px !important;
  }
  .pt5-d-s {
    padding-top: 80px !important;
  }
  .pr5-d-s {
    padding-right: 80px !important;
  }
  .pb5-d-s {
    padding-bottom: 80px !important;
  }
  .pl5-d-s {
    padding-left: 80px !important;
  }
  .pv5-d-s {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .ph5-d-s {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .m5-d-s {
    margin: 80px !important;
  }
  .mt5-d-s {
    margin-top: 80px !important;
  }
  .mr5-d-s {
    margin-right: 80px !important;
  }
  .mb5-d-s {
    margin-bottom: 80px !important;
  }
  .ml5-d-s {
    margin-left: 80px !important;
  }
  .mv5-d-s {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .mh5-d-s {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }
  .p6-d-s {
    padding: 96px !important;
  }
  .pt6-d-s {
    padding-top: 96px !important;
  }
  .pr6-d-s {
    padding-right: 96px !important;
  }
  .pb6-d-s {
    padding-bottom: 96px !important;
  }
  .pl6-d-s {
    padding-left: 96px !important;
  }
  .pv6-d-s {
    padding-top: 96px !important;
    padding-bottom: 96px !important;
  }
  .ph6-d-s {
    padding-left: 96px !important;
    padding-right: 96px !important;
  }
  .m6-d-s {
    margin: 96px !important;
  }
  .mt6-d-s {
    margin-top: 96px !important;
  }
  .mr6-d-s {
    margin-right: 96px !important;
  }
  .mb6-d-s {
    margin-bottom: 96px !important;
  }
  .ml6-d-s {
    margin-left: 96px !important;
  }
  .mv6-d-s {
    margin-top: 96px !important;
    margin-bottom: 96px !important;
  }
  .mh6-d-s {
    margin-left: 96px !important;
    margin-right: 96px !important;
  }
  .p7-d-s {
    padding: 112px !important;
  }
  .pt7-d-s {
    padding-top: 112px !important;
  }
  .pr7-d-s {
    padding-right: 112px !important;
  }
  .pb7-d-s {
    padding-bottom: 112px !important;
  }
  .pl7-d-s {
    padding-left: 112px !important;
  }
  .pv7-d-s {
    padding-top: 112px !important;
    padding-bottom: 112px !important;
  }
  .ph7-d-s {
    padding-left: 112px !important;
    padding-right: 112px !important;
  }
  .m7-d-s {
    margin: 112px !important;
  }
  .mt7-d-s {
    margin-top: 112px !important;
  }
  .mr7-d-s {
    margin-right: 112px !important;
  }
  .mb7-d-s {
    margin-bottom: 112px !important;
  }
  .ml7-d-s {
    margin-left: 112px !important;
  }
  .mv7-d-s {
    margin-top: 112px !important;
    margin-bottom: 112px !important;
  }
  .mh7-d-s {
    margin-left: 112px !important;
    margin-right: 112px !important;
  }
  .p8-d-s {
    padding: 128px !important;
  }
  .pt8-d-s {
    padding-top: 128px !important;
  }
  .pr8-d-s {
    padding-right: 128px !important;
  }
  .pb8-d-s {
    padding-bottom: 128px !important;
  }
  .pl8-d-s {
    padding-left: 128px !important;
  }
  .pv8-d-s {
    padding-top: 128px !important;
    padding-bottom: 128px !important;
  }
  .ph8-d-s {
    padding-left: 128px !important;
    padding-right: 128px !important;
  }
  .m8-d-s {
    margin: 128px !important;
  }
  .mt8-d-s {
    margin-top: 128px !important;
  }
  .mr8-d-s {
    margin-right: 128px !important;
  }
  .mb8-d-s {
    margin-bottom: 128px !important;
  }
  .ml8-d-s {
    margin-left: 128px !important;
  }
  .mv8-d-s {
    margin-top: 128px !important;
    margin-bottom: 128px !important;
  }
  .mh8-d-s {
    margin-left: 128px !important;
    margin-right: 128px !important;
  }
  .p9-d-s {
    padding: 144px !important;
  }
  .pt9-d-s {
    padding-top: 144px !important;
  }
  .pr9-d-s {
    padding-right: 144px !important;
  }
  .pb9-d-s {
    padding-bottom: 144px !important;
  }
  .pl9-d-s {
    padding-left: 144px !important;
  }
  .pv9-d-s {
    padding-top: 144px !important;
    padding-bottom: 144px !important;
  }
  .ph9-d-s {
    padding-left: 144px !important;
    padding-right: 144px !important;
  }
  .m9-d-s {
    margin: 144px !important;
  }
  .mt9-d-s {
    margin-top: 144px !important;
  }
  .mr9-d-s {
    margin-right: 144px !important;
  }
  .mb9-d-s {
    margin-bottom: 144px !important;
  }
  .ml9-d-s {
    margin-left: 144px !important;
  }
  .mv9-d-s {
    margin-top: 144px !important;
    margin-bottom: 144px !important;
  }
  .mh9-d-s {
    margin-left: 144px !important;
    margin-right: 144px !important;
  }
}
@media (min-width: 48em) {
  .p10-m {
    padding: 10% !important;
  }
  .pt10-m {
    padding-top: 10% !important;
  }
  .pr10-m {
    padding-right: 10% !important;
  }
  .pb10-m {
    padding-bottom: 10% !important;
  }
  .pl10-m {
    padding-left: 10% !important;
  }
  .pv10-m {
    padding-top: 10% !important;
    padding-bottom: 10% !important;
  }
  .ph10-m {
    padding-left: 10% !important;
    padding-right: 10% !important;
  }
  .m10-m {
    margin: 10% !important;
  }
  .mt10-m {
    margin-top: 10% !important;
  }
  .mr10-m {
    margin-right: 10% !important;
  }
  .mb10-m {
    margin-bottom: 10% !important;
  }
  .ml10-m {
    margin-left: 10% !important;
  }
  .mv10-m {
    margin-top: 10% !important;
    margin-bottom: 10% !important;
  }
  .mh10-m {
    margin-left: 10% !important;
    margin-right: 10% !important;
  }
  .p20-m {
    padding: 20% !important;
  }
  .pt20-m {
    padding-top: 20% !important;
  }
  .pr20-m {
    padding-right: 20% !important;
  }
  .pb20-m {
    padding-bottom: 20% !important;
  }
  .pl20-m {
    padding-left: 20% !important;
  }
  .pv20-m {
    padding-top: 20% !important;
    padding-bottom: 20% !important;
  }
  .ph20-m {
    padding-left: 20% !important;
    padding-right: 20% !important;
  }
  .m20-m {
    margin: 20% !important;
  }
  .mt20-m {
    margin-top: 20% !important;
  }
  .mr20-m {
    margin-right: 20% !important;
  }
  .mb20-m {
    margin-bottom: 20% !important;
  }
  .ml20-m {
    margin-left: 20% !important;
  }
  .mv20-m {
    margin-top: 20% !important;
    margin-bottom: 20% !important;
  }
  .mh20-m {
    margin-left: 20% !important;
    margin-right: 20% !important;
  }
  .p30-m {
    padding: 30% !important;
  }
  .pt30-m {
    padding-top: 30% !important;
  }
  .pr30-m {
    padding-right: 30% !important;
  }
  .pb30-m {
    padding-bottom: 30% !important;
  }
  .pl30-m {
    padding-left: 30% !important;
  }
  .pv30-m {
    padding-top: 30% !important;
    padding-bottom: 30% !important;
  }
  .ph30-m {
    padding-left: 30% !important;
    padding-right: 30% !important;
  }
  .m30-m {
    margin: 30% !important;
  }
  .mt30-m {
    margin-top: 30% !important;
  }
  .mr30-m {
    margin-right: 30% !important;
  }
  .mb30-m {
    margin-bottom: 30% !important;
  }
  .ml30-m {
    margin-left: 30% !important;
  }
  .mv30-m {
    margin-top: 30% !important;
    margin-bottom: 30% !important;
  }
  .mh30-m {
    margin-left: 30% !important;
    margin-right: 30% !important;
  }
  .p40-m {
    padding: 40% !important;
  }
  .pt40-m {
    padding-top: 40% !important;
  }
  .pr40-m {
    padding-right: 40% !important;
  }
  .pb40-m {
    padding-bottom: 40% !important;
  }
  .pl40-m {
    padding-left: 40% !important;
  }
  .pv40-m {
    padding-top: 40% !important;
    padding-bottom: 40% !important;
  }
  .ph40-m {
    padding-left: 40% !important;
    padding-right: 40% !important;
  }
  .m40-m {
    margin: 40% !important;
  }
  .mt40-m {
    margin-top: 40% !important;
  }
  .mr40-m {
    margin-right: 40% !important;
  }
  .mb40-m {
    margin-bottom: 40% !important;
  }
  .ml40-m {
    margin-left: 40% !important;
  }
  .mv40-m {
    margin-top: 40% !important;
    margin-bottom: 40% !important;
  }
  .mh40-m {
    margin-left: 40% !important;
    margin-right: 40% !important;
  }
  .p45-m {
    padding: 45% !important;
  }
  .pt45-m {
    padding-top: 45% !important;
  }
  .pr45-m {
    padding-right: 45% !important;
  }
  .pb45-m {
    padding-bottom: 45% !important;
  }
  .pl45-m {
    padding-left: 45% !important;
  }
  .pv45-m {
    padding-top: 45% !important;
    padding-bottom: 45% !important;
  }
  .ph45-m {
    padding-left: 45% !important;
    padding-right: 45% !important;
  }
  .m45-m {
    margin: 45% !important;
  }
  .mt45-m {
    margin-top: 45% !important;
  }
  .mr45-m {
    margin-right: 45% !important;
  }
  .mb45-m {
    margin-bottom: 45% !important;
  }
  .ml45-m {
    margin-left: 45% !important;
  }
  .mv45-m {
    margin-top: 45% !important;
    margin-bottom: 45% !important;
  }
  .mh45-m {
    margin-left: 45% !important;
    margin-right: 45% !important;
  }
  .p50-m {
    padding: 50% !important;
  }
  .pt50-m {
    padding-top: 50% !important;
  }
  .pr50-m {
    padding-right: 50% !important;
  }
  .pb50-m {
    padding-bottom: 50% !important;
  }
  .pl50-m {
    padding-left: 50% !important;
  }
  .pv50-m {
    padding-top: 50% !important;
    padding-bottom: 50% !important;
  }
  .ph50-m {
    padding-left: 50% !important;
    padding-right: 50% !important;
  }
  .m50-m {
    margin: 50% !important;
  }
  .mt50-m {
    margin-top: 50% !important;
  }
  .mr50-m {
    margin-right: 50% !important;
  }
  .mb50-m {
    margin-bottom: 50% !important;
  }
  .ml50-m {
    margin-left: 50% !important;
  }
  .mv50-m {
    margin-top: 50% !important;
    margin-bottom: 50% !important;
  }
  .mh50-m {
    margin-left: 50% !important;
    margin-right: 50% !important;
  }
  .p60-m {
    padding: 60% !important;
  }
  .pt60-m {
    padding-top: 60% !important;
  }
  .pr60-m {
    padding-right: 60% !important;
  }
  .pb60-m {
    padding-bottom: 60% !important;
  }
  .pl60-m {
    padding-left: 60% !important;
  }
  .pv60-m {
    padding-top: 60% !important;
    padding-bottom: 60% !important;
  }
  .ph60-m {
    padding-left: 60% !important;
    padding-right: 60% !important;
  }
  .m60-m {
    margin: 60% !important;
  }
  .mt60-m {
    margin-top: 60% !important;
  }
  .mr60-m {
    margin-right: 60% !important;
  }
  .mb60-m {
    margin-bottom: 60% !important;
  }
  .ml60-m {
    margin-left: 60% !important;
  }
  .mv60-m {
    margin-top: 60% !important;
    margin-bottom: 60% !important;
  }
  .mh60-m {
    margin-left: 60% !important;
    margin-right: 60% !important;
  }
  .p70-m {
    padding: 70% !important;
  }
  .pt70-m {
    padding-top: 70% !important;
  }
  .pr70-m {
    padding-right: 70% !important;
  }
  .pb70-m {
    padding-bottom: 70% !important;
  }
  .pl70-m {
    padding-left: 70% !important;
  }
  .pv70-m {
    padding-top: 70% !important;
    padding-bottom: 70% !important;
  }
  .ph70-m {
    padding-left: 70% !important;
    padding-right: 70% !important;
  }
  .m70-m {
    margin: 70% !important;
  }
  .mt70-m {
    margin-top: 70% !important;
  }
  .mr70-m {
    margin-right: 70% !important;
  }
  .mb70-m {
    margin-bottom: 70% !important;
  }
  .ml70-m {
    margin-left: 70% !important;
  }
  .mv70-m {
    margin-top: 70% !important;
    margin-bottom: 70% !important;
  }
  .mh70-m {
    margin-left: 70% !important;
    margin-right: 70% !important;
  }
  .p80-m {
    padding: 80% !important;
  }
  .pt80-m {
    padding-top: 80% !important;
  }
  .pr80-m {
    padding-right: 80% !important;
  }
  .pb80-m {
    padding-bottom: 80% !important;
  }
  .pl80-m {
    padding-left: 80% !important;
  }
  .pv80-m {
    padding-top: 80% !important;
    padding-bottom: 80% !important;
  }
  .ph80-m {
    padding-left: 80% !important;
    padding-right: 80% !important;
  }
  .m80-m {
    margin: 80% !important;
  }
  .mt80-m {
    margin-top: 80% !important;
  }
  .mr80-m {
    margin-right: 80% !important;
  }
  .mb80-m {
    margin-bottom: 80% !important;
  }
  .ml80-m {
    margin-left: 80% !important;
  }
  .mv80-m {
    margin-top: 80% !important;
    margin-bottom: 80% !important;
  }
  .mh80-m {
    margin-left: 80% !important;
    margin-right: 80% !important;
  }
  .p90-m {
    padding: 90% !important;
  }
  .pt90-m {
    padding-top: 90% !important;
  }
  .pr90-m {
    padding-right: 90% !important;
  }
  .pb90-m {
    padding-bottom: 90% !important;
  }
  .pl90-m {
    padding-left: 90% !important;
  }
  .pv90-m {
    padding-top: 90% !important;
    padding-bottom: 90% !important;
  }
  .ph90-m {
    padding-left: 90% !important;
    padding-right: 90% !important;
  }
  .m90-m {
    margin: 90% !important;
  }
  .mt90-m {
    margin-top: 90% !important;
  }
  .mr90-m {
    margin-right: 90% !important;
  }
  .mb90-m {
    margin-bottom: 90% !important;
  }
  .ml90-m {
    margin-left: 90% !important;
  }
  .mv90-m {
    margin-top: 90% !important;
    margin-bottom: 90% !important;
  }
  .mh90-m {
    margin-left: 90% !important;
    margin-right: 90% !important;
  }
  .p100-m {
    padding: 100% !important;
  }
  .pt100-m {
    padding-top: 100% !important;
  }
  .pr100-m {
    padding-right: 100% !important;
  }
  .pb100-m {
    padding-bottom: 100% !important;
  }
  .pl100-m {
    padding-left: 100% !important;
  }
  .pv100-m {
    padding-top: 100% !important;
    padding-bottom: 100% !important;
  }
  .ph100-m {
    padding-left: 100% !important;
    padding-right: 100% !important;
  }
  .m100-m {
    margin: 100% !important;
  }
  .mt100-m {
    margin-top: 100% !important;
  }
  .mr100-m {
    margin-right: 100% !important;
  }
  .mb100-m {
    margin-bottom: 100% !important;
  }
  .ml100-m {
    margin-left: 100% !important;
  }
  .mv100-m {
    margin-top: 100% !important;
    margin-bottom: 100% !important;
  }
  .mh100-m {
    margin-left: 100% !important;
    margin-right: 100% !important;
  }
  .pauto-m {
    padding: auto !important;
  }
  .ptauto-m {
    padding-top: auto !important;
  }
  .prauto-m {
    padding-right: auto !important;
  }
  .pbauto-m {
    padding-bottom: auto !important;
  }
  .plauto-m {
    padding-left: auto !important;
  }
  .pvauto-m {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
  .phauto-m {
    padding-left: auto !important;
    padding-right: auto !important;
  }
  .mauto-m {
    margin: auto !important;
  }
  .mtauto-m {
    margin-top: auto !important;
  }
  .mrauto-m {
    margin-right: auto !important;
  }
  .mbauto-m {
    margin-bottom: auto !important;
  }
  .mlauto-m {
    margin-left: auto !important;
  }
  .mvauto-m {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mhauto-m {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .p0-m {
    padding: 0px !important;
  }
  .pt0-m {
    padding-top: 0px !important;
  }
  .pr0-m {
    padding-right: 0px !important;
  }
  .pb0-m {
    padding-bottom: 0px !important;
  }
  .pl0-m {
    padding-left: 0px !important;
  }
  .pv0-m {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .ph0-m {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .m0-m {
    margin: 0px !important;
  }
  .mt0-m {
    margin-top: 0px !important;
  }
  .mr0-m {
    margin-right: 0px !important;
  }
  .mb0-m {
    margin-bottom: 0px !important;
  }
  .ml0-m {
    margin-left: 0px !important;
  }
  .mv0-m {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .mh0-m {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .p1-m {
    padding: 4px !important;
  }
  .pt1-m {
    padding-top: 4px !important;
  }
  .pr1-m {
    padding-right: 4px !important;
  }
  .pb1-m {
    padding-bottom: 4px !important;
  }
  .pl1-m {
    padding-left: 4px !important;
  }
  .pv1-m {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
  .ph1-m {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
  .m1-m {
    margin: 4px !important;
  }
  .mt1-m {
    margin-top: 4px !important;
  }
  .mr1-m {
    margin-right: 4px !important;
  }
  .mb1-m {
    margin-bottom: 4px !important;
  }
  .ml1-m {
    margin-left: 4px !important;
  }
  .mv1-m {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }
  .mh1-m {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }
  .p2-m {
    padding: 8px !important;
  }
  .pt2-m {
    padding-top: 8px !important;
  }
  .pr2-m {
    padding-right: 8px !important;
  }
  .pb2-m {
    padding-bottom: 8px !important;
  }
  .pl2-m {
    padding-left: 8px !important;
  }
  .pv2-m {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .ph2-m {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .m2-m {
    margin: 8px !important;
  }
  .mt2-m {
    margin-top: 8px !important;
  }
  .mr2-m {
    margin-right: 8px !important;
  }
  .mb2-m {
    margin-bottom: 8px !important;
  }
  .ml2-m {
    margin-left: 8px !important;
  }
  .mv2-m {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .mh2-m {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
  .p3-m {
    padding: 12px !important;
  }
  .pt3-m {
    padding-top: 12px !important;
  }
  .pr3-m {
    padding-right: 12px !important;
  }
  .pb3-m {
    padding-bottom: 12px !important;
  }
  .pl3-m {
    padding-left: 12px !important;
  }
  .pv3-m {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .ph3-m {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .m3-m {
    margin: 12px !important;
  }
  .mt3-m {
    margin-top: 12px !important;
  }
  .mr3-m {
    margin-right: 12px !important;
  }
  .mb3-m {
    margin-bottom: 12px !important;
  }
  .ml3-m {
    margin-left: 12px !important;
  }
  .mv3-m {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .mh3-m {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }
  .p4-m {
    padding: 16px !important;
  }
  .pt4-m {
    padding-top: 16px !important;
  }
  .pr4-m {
    padding-right: 16px !important;
  }
  .pb4-m {
    padding-bottom: 16px !important;
  }
  .pl4-m {
    padding-left: 16px !important;
  }
  .pv4-m {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  .ph4-m {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .m4-m {
    margin: 16px !important;
  }
  .mt4-m {
    margin-top: 16px !important;
  }
  .mr4-m {
    margin-right: 16px !important;
  }
  .mb4-m {
    margin-bottom: 16px !important;
  }
  .ml4-m {
    margin-left: 16px !important;
  }
  .mv4-m {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  .mh4-m {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }
  .p5-m {
    padding: 20px !important;
  }
  .pt5-m {
    padding-top: 20px !important;
  }
  .pr5-m {
    padding-right: 20px !important;
  }
  .pb5-m {
    padding-bottom: 20px !important;
  }
  .pl5-m {
    padding-left: 20px !important;
  }
  .pv5-m {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .ph5-m {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .m5-m {
    margin: 20px !important;
  }
  .mt5-m {
    margin-top: 20px !important;
  }
  .mr5-m {
    margin-right: 20px !important;
  }
  .mb5-m {
    margin-bottom: 20px !important;
  }
  .ml5-m {
    margin-left: 20px !important;
  }
  .mv5-m {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .mh5-m {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .p6-m {
    padding: 24px !important;
  }
  .pt6-m {
    padding-top: 24px !important;
  }
  .pr6-m {
    padding-right: 24px !important;
  }
  .pb6-m {
    padding-bottom: 24px !important;
  }
  .pl6-m {
    padding-left: 24px !important;
  }
  .pv6-m {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
  .ph6-m {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .m6-m {
    margin: 24px !important;
  }
  .mt6-m {
    margin-top: 24px !important;
  }
  .mr6-m {
    margin-right: 24px !important;
  }
  .mb6-m {
    margin-bottom: 24px !important;
  }
  .ml6-m {
    margin-left: 24px !important;
  }
  .mv6-m {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }
  .mh6-m {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }
  .p7-m {
    padding: 28px !important;
  }
  .pt7-m {
    padding-top: 28px !important;
  }
  .pr7-m {
    padding-right: 28px !important;
  }
  .pb7-m {
    padding-bottom: 28px !important;
  }
  .pl7-m {
    padding-left: 28px !important;
  }
  .pv7-m {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }
  .ph7-m {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }
  .m7-m {
    margin: 28px !important;
  }
  .mt7-m {
    margin-top: 28px !important;
  }
  .mr7-m {
    margin-right: 28px !important;
  }
  .mb7-m {
    margin-bottom: 28px !important;
  }
  .ml7-m {
    margin-left: 28px !important;
  }
  .mv7-m {
    margin-top: 28px !important;
    margin-bottom: 28px !important;
  }
  .mh7-m {
    margin-left: 28px !important;
    margin-right: 28px !important;
  }
  .p8-m {
    padding: 32px !important;
  }
  .pt8-m {
    padding-top: 32px !important;
  }
  .pr8-m {
    padding-right: 32px !important;
  }
  .pb8-m {
    padding-bottom: 32px !important;
  }
  .pl8-m {
    padding-left: 32px !important;
  }
  .pv8-m {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }
  .ph8-m {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }
  .m8-m {
    margin: 32px !important;
  }
  .mt8-m {
    margin-top: 32px !important;
  }
  .mr8-m {
    margin-right: 32px !important;
  }
  .mb8-m {
    margin-bottom: 32px !important;
  }
  .ml8-m {
    margin-left: 32px !important;
  }
  .mv8-m {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }
  .mh8-m {
    margin-left: 32px !important;
    margin-right: 32px !important;
  }
  .p9-m {
    padding: 36px !important;
  }
  .pt9-m {
    padding-top: 36px !important;
  }
  .pr9-m {
    padding-right: 36px !important;
  }
  .pb9-m {
    padding-bottom: 36px !important;
  }
  .pl9-m {
    padding-left: 36px !important;
  }
  .pv9-m {
    padding-top: 36px !important;
    padding-bottom: 36px !important;
  }
  .ph9-m {
    padding-left: 36px !important;
    padding-right: 36px !important;
  }
  .m9-m {
    margin: 36px !important;
  }
  .mt9-m {
    margin-top: 36px !important;
  }
  .mr9-m {
    margin-right: 36px !important;
  }
  .mb9-m {
    margin-bottom: 36px !important;
  }
  .ml9-m {
    margin-left: 36px !important;
  }
  .mv9-m {
    margin-top: 36px !important;
    margin-bottom: 36px !important;
  }
  .mh9-m {
    margin-left: 36px !important;
    margin-right: 36px !important;
  }
  .p3-d-m {
    padding: 48px !important;
  }
  .pt3-d-m {
    padding-top: 48px !important;
  }
  .pr3-d-m {
    padding-right: 48px !important;
  }
  .pb3-d-m {
    padding-bottom: 48px !important;
  }
  .pl3-d-m {
    padding-left: 48px !important;
  }
  .pv3-d-m {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }
  .ph3-d-m {
    padding-left: 48px !important;
    padding-right: 48px !important;
  }
  .m3-d-m {
    margin: 48px !important;
  }
  .mt3-d-m {
    margin-top: 48px !important;
  }
  .mr3-d-m {
    margin-right: 48px !important;
  }
  .mb3-d-m {
    margin-bottom: 48px !important;
  }
  .ml3-d-m {
    margin-left: 48px !important;
  }
  .mv3-d-m {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }
  .mh3-d-m {
    margin-left: 48px !important;
    margin-right: 48px !important;
  }
  .p4-d-m {
    padding: 64px !important;
  }
  .pt4-d-m {
    padding-top: 64px !important;
  }
  .pr4-d-m {
    padding-right: 64px !important;
  }
  .pb4-d-m {
    padding-bottom: 64px !important;
  }
  .pl4-d-m {
    padding-left: 64px !important;
  }
  .pv4-d-m {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }
  .ph4-d-m {
    padding-left: 64px !important;
    padding-right: 64px !important;
  }
  .m4-d-m {
    margin: 64px !important;
  }
  .mt4-d-m {
    margin-top: 64px !important;
  }
  .mr4-d-m {
    margin-right: 64px !important;
  }
  .mb4-d-m {
    margin-bottom: 64px !important;
  }
  .ml4-d-m {
    margin-left: 64px !important;
  }
  .mv4-d-m {
    margin-top: 64px !important;
    margin-bottom: 64px !important;
  }
  .mh4-d-m {
    margin-left: 64px !important;
    margin-right: 64px !important;
  }
  .p5-d-m {
    padding: 80px !important;
  }
  .pt5-d-m {
    padding-top: 80px !important;
  }
  .pr5-d-m {
    padding-right: 80px !important;
  }
  .pb5-d-m {
    padding-bottom: 80px !important;
  }
  .pl5-d-m {
    padding-left: 80px !important;
  }
  .pv5-d-m {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .ph5-d-m {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .m5-d-m {
    margin: 80px !important;
  }
  .mt5-d-m {
    margin-top: 80px !important;
  }
  .mr5-d-m {
    margin-right: 80px !important;
  }
  .mb5-d-m {
    margin-bottom: 80px !important;
  }
  .ml5-d-m {
    margin-left: 80px !important;
  }
  .mv5-d-m {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .mh5-d-m {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }
  .p6-d-m {
    padding: 96px !important;
  }
  .pt6-d-m {
    padding-top: 96px !important;
  }
  .pr6-d-m {
    padding-right: 96px !important;
  }
  .pb6-d-m {
    padding-bottom: 96px !important;
  }
  .pl6-d-m {
    padding-left: 96px !important;
  }
  .pv6-d-m {
    padding-top: 96px !important;
    padding-bottom: 96px !important;
  }
  .ph6-d-m {
    padding-left: 96px !important;
    padding-right: 96px !important;
  }
  .m6-d-m {
    margin: 96px !important;
  }
  .mt6-d-m {
    margin-top: 96px !important;
  }
  .mr6-d-m {
    margin-right: 96px !important;
  }
  .mb6-d-m {
    margin-bottom: 96px !important;
  }
  .ml6-d-m {
    margin-left: 96px !important;
  }
  .mv6-d-m {
    margin-top: 96px !important;
    margin-bottom: 96px !important;
  }
  .mh6-d-m {
    margin-left: 96px !important;
    margin-right: 96px !important;
  }
  .p7-d-m {
    padding: 112px !important;
  }
  .pt7-d-m {
    padding-top: 112px !important;
  }
  .pr7-d-m {
    padding-right: 112px !important;
  }
  .pb7-d-m {
    padding-bottom: 112px !important;
  }
  .pl7-d-m {
    padding-left: 112px !important;
  }
  .pv7-d-m {
    padding-top: 112px !important;
    padding-bottom: 112px !important;
  }
  .ph7-d-m {
    padding-left: 112px !important;
    padding-right: 112px !important;
  }
  .m7-d-m {
    margin: 112px !important;
  }
  .mt7-d-m {
    margin-top: 112px !important;
  }
  .mr7-d-m {
    margin-right: 112px !important;
  }
  .mb7-d-m {
    margin-bottom: 112px !important;
  }
  .ml7-d-m {
    margin-left: 112px !important;
  }
  .mv7-d-m {
    margin-top: 112px !important;
    margin-bottom: 112px !important;
  }
  .mh7-d-m {
    margin-left: 112px !important;
    margin-right: 112px !important;
  }
  .p8-d-m {
    padding: 128px !important;
  }
  .pt8-d-m {
    padding-top: 128px !important;
  }
  .pr8-d-m {
    padding-right: 128px !important;
  }
  .pb8-d-m {
    padding-bottom: 128px !important;
  }
  .pl8-d-m {
    padding-left: 128px !important;
  }
  .pv8-d-m {
    padding-top: 128px !important;
    padding-bottom: 128px !important;
  }
  .ph8-d-m {
    padding-left: 128px !important;
    padding-right: 128px !important;
  }
  .m8-d-m {
    margin: 128px !important;
  }
  .mt8-d-m {
    margin-top: 128px !important;
  }
  .mr8-d-m {
    margin-right: 128px !important;
  }
  .mb8-d-m {
    margin-bottom: 128px !important;
  }
  .ml8-d-m {
    margin-left: 128px !important;
  }
  .mv8-d-m {
    margin-top: 128px !important;
    margin-bottom: 128px !important;
  }
  .mh8-d-m {
    margin-left: 128px !important;
    margin-right: 128px !important;
  }
  .p9-d-m {
    padding: 144px !important;
  }
  .pt9-d-m {
    padding-top: 144px !important;
  }
  .pr9-d-m {
    padding-right: 144px !important;
  }
  .pb9-d-m {
    padding-bottom: 144px !important;
  }
  .pl9-d-m {
    padding-left: 144px !important;
  }
  .pv9-d-m {
    padding-top: 144px !important;
    padding-bottom: 144px !important;
  }
  .ph9-d-m {
    padding-left: 144px !important;
    padding-right: 144px !important;
  }
  .m9-d-m {
    margin: 144px !important;
  }
  .mt9-d-m {
    margin-top: 144px !important;
  }
  .mr9-d-m {
    margin-right: 144px !important;
  }
  .mb9-d-m {
    margin-bottom: 144px !important;
  }
  .ml9-d-m {
    margin-left: 144px !important;
  }
  .mv9-d-m {
    margin-top: 144px !important;
    margin-bottom: 144px !important;
  }
  .mh9-d-m {
    margin-left: 144px !important;
    margin-right: 144px !important;
  }
}
@media (min-width: 65em) {
  .p10-l {
    padding: 10% !important;
  }
  .pt10-l {
    padding-top: 10% !important;
  }
  .pr10-l {
    padding-right: 10% !important;
  }
  .pb10-l {
    padding-bottom: 10% !important;
  }
  .pl10-l {
    padding-left: 10% !important;
  }
  .pv10-l {
    padding-top: 10% !important;
    padding-bottom: 10% !important;
  }
  .ph10-l {
    padding-left: 10% !important;
    padding-right: 10% !important;
  }
  .m10-l {
    margin: 10% !important;
  }
  .mt10-l {
    margin-top: 10% !important;
  }
  .mr10-l {
    margin-right: 10% !important;
  }
  .mb10-l {
    margin-bottom: 10% !important;
  }
  .ml10-l {
    margin-left: 10% !important;
  }
  .mv10-l {
    margin-top: 10% !important;
    margin-bottom: 10% !important;
  }
  .mh10-l {
    margin-left: 10% !important;
    margin-right: 10% !important;
  }
  .p20-l {
    padding: 20% !important;
  }
  .pt20-l {
    padding-top: 20% !important;
  }
  .pr20-l {
    padding-right: 20% !important;
  }
  .pb20-l {
    padding-bottom: 20% !important;
  }
  .pl20-l {
    padding-left: 20% !important;
  }
  .pv20-l {
    padding-top: 20% !important;
    padding-bottom: 20% !important;
  }
  .ph20-l {
    padding-left: 20% !important;
    padding-right: 20% !important;
  }
  .m20-l {
    margin: 20% !important;
  }
  .mt20-l {
    margin-top: 20% !important;
  }
  .mr20-l {
    margin-right: 20% !important;
  }
  .mb20-l {
    margin-bottom: 20% !important;
  }
  .ml20-l {
    margin-left: 20% !important;
  }
  .mv20-l {
    margin-top: 20% !important;
    margin-bottom: 20% !important;
  }
  .mh20-l {
    margin-left: 20% !important;
    margin-right: 20% !important;
  }
  .p30-l {
    padding: 30% !important;
  }
  .pt30-l {
    padding-top: 30% !important;
  }
  .pr30-l {
    padding-right: 30% !important;
  }
  .pb30-l {
    padding-bottom: 30% !important;
  }
  .pl30-l {
    padding-left: 30% !important;
  }
  .pv30-l {
    padding-top: 30% !important;
    padding-bottom: 30% !important;
  }
  .ph30-l {
    padding-left: 30% !important;
    padding-right: 30% !important;
  }
  .m30-l {
    margin: 30% !important;
  }
  .mt30-l {
    margin-top: 30% !important;
  }
  .mr30-l {
    margin-right: 30% !important;
  }
  .mb30-l {
    margin-bottom: 30% !important;
  }
  .ml30-l {
    margin-left: 30% !important;
  }
  .mv30-l {
    margin-top: 30% !important;
    margin-bottom: 30% !important;
  }
  .mh30-l {
    margin-left: 30% !important;
    margin-right: 30% !important;
  }
  .p40-l {
    padding: 40% !important;
  }
  .pt40-l {
    padding-top: 40% !important;
  }
  .pr40-l {
    padding-right: 40% !important;
  }
  .pb40-l {
    padding-bottom: 40% !important;
  }
  .pl40-l {
    padding-left: 40% !important;
  }
  .pv40-l {
    padding-top: 40% !important;
    padding-bottom: 40% !important;
  }
  .ph40-l {
    padding-left: 40% !important;
    padding-right: 40% !important;
  }
  .m40-l {
    margin: 40% !important;
  }
  .mt40-l {
    margin-top: 40% !important;
  }
  .mr40-l {
    margin-right: 40% !important;
  }
  .mb40-l {
    margin-bottom: 40% !important;
  }
  .ml40-l {
    margin-left: 40% !important;
  }
  .mv40-l {
    margin-top: 40% !important;
    margin-bottom: 40% !important;
  }
  .mh40-l {
    margin-left: 40% !important;
    margin-right: 40% !important;
  }
  .p45-l {
    padding: 45% !important;
  }
  .pt45-l {
    padding-top: 45% !important;
  }
  .pr45-l {
    padding-right: 45% !important;
  }
  .pb45-l {
    padding-bottom: 45% !important;
  }
  .pl45-l {
    padding-left: 45% !important;
  }
  .pv45-l {
    padding-top: 45% !important;
    padding-bottom: 45% !important;
  }
  .ph45-l {
    padding-left: 45% !important;
    padding-right: 45% !important;
  }
  .m45-l {
    margin: 45% !important;
  }
  .mt45-l {
    margin-top: 45% !important;
  }
  .mr45-l {
    margin-right: 45% !important;
  }
  .mb45-l {
    margin-bottom: 45% !important;
  }
  .ml45-l {
    margin-left: 45% !important;
  }
  .mv45-l {
    margin-top: 45% !important;
    margin-bottom: 45% !important;
  }
  .mh45-l {
    margin-left: 45% !important;
    margin-right: 45% !important;
  }
  .p50-l {
    padding: 50% !important;
  }
  .pt50-l {
    padding-top: 50% !important;
  }
  .pr50-l {
    padding-right: 50% !important;
  }
  .pb50-l {
    padding-bottom: 50% !important;
  }
  .pl50-l {
    padding-left: 50% !important;
  }
  .pv50-l {
    padding-top: 50% !important;
    padding-bottom: 50% !important;
  }
  .ph50-l {
    padding-left: 50% !important;
    padding-right: 50% !important;
  }
  .m50-l {
    margin: 50% !important;
  }
  .mt50-l {
    margin-top: 50% !important;
  }
  .mr50-l {
    margin-right: 50% !important;
  }
  .mb50-l {
    margin-bottom: 50% !important;
  }
  .ml50-l {
    margin-left: 50% !important;
  }
  .mv50-l {
    margin-top: 50% !important;
    margin-bottom: 50% !important;
  }
  .mh50-l {
    margin-left: 50% !important;
    margin-right: 50% !important;
  }
  .p60-l {
    padding: 60% !important;
  }
  .pt60-l {
    padding-top: 60% !important;
  }
  .pr60-l {
    padding-right: 60% !important;
  }
  .pb60-l {
    padding-bottom: 60% !important;
  }
  .pl60-l {
    padding-left: 60% !important;
  }
  .pv60-l {
    padding-top: 60% !important;
    padding-bottom: 60% !important;
  }
  .ph60-l {
    padding-left: 60% !important;
    padding-right: 60% !important;
  }
  .m60-l {
    margin: 60% !important;
  }
  .mt60-l {
    margin-top: 60% !important;
  }
  .mr60-l {
    margin-right: 60% !important;
  }
  .mb60-l {
    margin-bottom: 60% !important;
  }
  .ml60-l {
    margin-left: 60% !important;
  }
  .mv60-l {
    margin-top: 60% !important;
    margin-bottom: 60% !important;
  }
  .mh60-l {
    margin-left: 60% !important;
    margin-right: 60% !important;
  }
  .p70-l {
    padding: 70% !important;
  }
  .pt70-l {
    padding-top: 70% !important;
  }
  .pr70-l {
    padding-right: 70% !important;
  }
  .pb70-l {
    padding-bottom: 70% !important;
  }
  .pl70-l {
    padding-left: 70% !important;
  }
  .pv70-l {
    padding-top: 70% !important;
    padding-bottom: 70% !important;
  }
  .ph70-l {
    padding-left: 70% !important;
    padding-right: 70% !important;
  }
  .m70-l {
    margin: 70% !important;
  }
  .mt70-l {
    margin-top: 70% !important;
  }
  .mr70-l {
    margin-right: 70% !important;
  }
  .mb70-l {
    margin-bottom: 70% !important;
  }
  .ml70-l {
    margin-left: 70% !important;
  }
  .mv70-l {
    margin-top: 70% !important;
    margin-bottom: 70% !important;
  }
  .mh70-l {
    margin-left: 70% !important;
    margin-right: 70% !important;
  }
  .p80-l {
    padding: 80% !important;
  }
  .pt80-l {
    padding-top: 80% !important;
  }
  .pr80-l {
    padding-right: 80% !important;
  }
  .pb80-l {
    padding-bottom: 80% !important;
  }
  .pl80-l {
    padding-left: 80% !important;
  }
  .pv80-l {
    padding-top: 80% !important;
    padding-bottom: 80% !important;
  }
  .ph80-l {
    padding-left: 80% !important;
    padding-right: 80% !important;
  }
  .m80-l {
    margin: 80% !important;
  }
  .mt80-l {
    margin-top: 80% !important;
  }
  .mr80-l {
    margin-right: 80% !important;
  }
  .mb80-l {
    margin-bottom: 80% !important;
  }
  .ml80-l {
    margin-left: 80% !important;
  }
  .mv80-l {
    margin-top: 80% !important;
    margin-bottom: 80% !important;
  }
  .mh80-l {
    margin-left: 80% !important;
    margin-right: 80% !important;
  }
  .p90-l {
    padding: 90% !important;
  }
  .pt90-l {
    padding-top: 90% !important;
  }
  .pr90-l {
    padding-right: 90% !important;
  }
  .pb90-l {
    padding-bottom: 90% !important;
  }
  .pl90-l {
    padding-left: 90% !important;
  }
  .pv90-l {
    padding-top: 90% !important;
    padding-bottom: 90% !important;
  }
  .ph90-l {
    padding-left: 90% !important;
    padding-right: 90% !important;
  }
  .m90-l {
    margin: 90% !important;
  }
  .mt90-l {
    margin-top: 90% !important;
  }
  .mr90-l {
    margin-right: 90% !important;
  }
  .mb90-l {
    margin-bottom: 90% !important;
  }
  .ml90-l {
    margin-left: 90% !important;
  }
  .mv90-l {
    margin-top: 90% !important;
    margin-bottom: 90% !important;
  }
  .mh90-l {
    margin-left: 90% !important;
    margin-right: 90% !important;
  }
  .p100-l {
    padding: 100% !important;
  }
  .pt100-l {
    padding-top: 100% !important;
  }
  .pr100-l {
    padding-right: 100% !important;
  }
  .pb100-l {
    padding-bottom: 100% !important;
  }
  .pl100-l {
    padding-left: 100% !important;
  }
  .pv100-l {
    padding-top: 100% !important;
    padding-bottom: 100% !important;
  }
  .ph100-l {
    padding-left: 100% !important;
    padding-right: 100% !important;
  }
  .m100-l {
    margin: 100% !important;
  }
  .mt100-l {
    margin-top: 100% !important;
  }
  .mr100-l {
    margin-right: 100% !important;
  }
  .mb100-l {
    margin-bottom: 100% !important;
  }
  .ml100-l {
    margin-left: 100% !important;
  }
  .mv100-l {
    margin-top: 100% !important;
    margin-bottom: 100% !important;
  }
  .mh100-l {
    margin-left: 100% !important;
    margin-right: 100% !important;
  }
  .pauto-l {
    padding: auto !important;
  }
  .ptauto-l {
    padding-top: auto !important;
  }
  .prauto-l {
    padding-right: auto !important;
  }
  .pbauto-l {
    padding-bottom: auto !important;
  }
  .plauto-l {
    padding-left: auto !important;
  }
  .pvauto-l {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
  .phauto-l {
    padding-left: auto !important;
    padding-right: auto !important;
  }
  .mauto-l {
    margin: auto !important;
  }
  .mtauto-l {
    margin-top: auto !important;
  }
  .mrauto-l {
    margin-right: auto !important;
  }
  .mbauto-l {
    margin-bottom: auto !important;
  }
  .mlauto-l {
    margin-left: auto !important;
  }
  .mvauto-l {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mhauto-l {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .p0-l {
    padding: 0px !important;
  }
  .pt0-l {
    padding-top: 0px !important;
  }
  .pr0-l {
    padding-right: 0px !important;
  }
  .pb0-l {
    padding-bottom: 0px !important;
  }
  .pl0-l {
    padding-left: 0px !important;
  }
  .pv0-l {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .ph0-l {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .m0-l {
    margin: 0px !important;
  }
  .mt0-l {
    margin-top: 0px !important;
  }
  .mr0-l {
    margin-right: 0px !important;
  }
  .mb0-l {
    margin-bottom: 0px !important;
  }
  .ml0-l {
    margin-left: 0px !important;
  }
  .mv0-l {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .mh0-l {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .p1-l {
    padding: 4px !important;
  }
  .pt1-l {
    padding-top: 4px !important;
  }
  .pr1-l {
    padding-right: 4px !important;
  }
  .pb1-l {
    padding-bottom: 4px !important;
  }
  .pl1-l {
    padding-left: 4px !important;
  }
  .pv1-l {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
  .ph1-l {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
  .m1-l {
    margin: 4px !important;
  }
  .mt1-l {
    margin-top: 4px !important;
  }
  .mr1-l {
    margin-right: 4px !important;
  }
  .mb1-l {
    margin-bottom: 4px !important;
  }
  .ml1-l {
    margin-left: 4px !important;
  }
  .mv1-l {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }
  .mh1-l {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }
  .p2-l {
    padding: 8px !important;
  }
  .pt2-l {
    padding-top: 8px !important;
  }
  .pr2-l {
    padding-right: 8px !important;
  }
  .pb2-l {
    padding-bottom: 8px !important;
  }
  .pl2-l {
    padding-left: 8px !important;
  }
  .pv2-l {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .ph2-l {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .m2-l {
    margin: 8px !important;
  }
  .mt2-l {
    margin-top: 8px !important;
  }
  .mr2-l {
    margin-right: 8px !important;
  }
  .mb2-l {
    margin-bottom: 8px !important;
  }
  .ml2-l {
    margin-left: 8px !important;
  }
  .mv2-l {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .mh2-l {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
  .p3-l {
    padding: 12px !important;
  }
  .pt3-l {
    padding-top: 12px !important;
  }
  .pr3-l {
    padding-right: 12px !important;
  }
  .pb3-l {
    padding-bottom: 12px !important;
  }
  .pl3-l {
    padding-left: 12px !important;
  }
  .pv3-l {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .ph3-l {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .m3-l {
    margin: 12px !important;
  }
  .mt3-l {
    margin-top: 12px !important;
  }
  .mr3-l {
    margin-right: 12px !important;
  }
  .mb3-l {
    margin-bottom: 12px !important;
  }
  .ml3-l {
    margin-left: 12px !important;
  }
  .mv3-l {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .mh3-l {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }
  .p4-l {
    padding: 16px !important;
  }
  .pt4-l {
    padding-top: 16px !important;
  }
  .pr4-l {
    padding-right: 16px !important;
  }
  .pb4-l {
    padding-bottom: 16px !important;
  }
  .pl4-l {
    padding-left: 16px !important;
  }
  .pv4-l {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  .ph4-l {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .m4-l {
    margin: 16px !important;
  }
  .mt4-l {
    margin-top: 16px !important;
  }
  .mr4-l {
    margin-right: 16px !important;
  }
  .mb4-l {
    margin-bottom: 16px !important;
  }
  .ml4-l {
    margin-left: 16px !important;
  }
  .mv4-l {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  .mh4-l {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }
  .p5-l {
    padding: 20px !important;
  }
  .pt5-l {
    padding-top: 20px !important;
  }
  .pr5-l {
    padding-right: 20px !important;
  }
  .pb5-l {
    padding-bottom: 20px !important;
  }
  .pl5-l {
    padding-left: 20px !important;
  }
  .pv5-l {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .ph5-l {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .m5-l {
    margin: 20px !important;
  }
  .mt5-l {
    margin-top: 20px !important;
  }
  .mr5-l {
    margin-right: 20px !important;
  }
  .mb5-l {
    margin-bottom: 20px !important;
  }
  .ml5-l {
    margin-left: 20px !important;
  }
  .mv5-l {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .mh5-l {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .p6-l {
    padding: 24px !important;
  }
  .pt6-l {
    padding-top: 24px !important;
  }
  .pr6-l {
    padding-right: 24px !important;
  }
  .pb6-l {
    padding-bottom: 24px !important;
  }
  .pl6-l {
    padding-left: 24px !important;
  }
  .pv6-l {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
  .ph6-l {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .m6-l {
    margin: 24px !important;
  }
  .mt6-l {
    margin-top: 24px !important;
  }
  .mr6-l {
    margin-right: 24px !important;
  }
  .mb6-l {
    margin-bottom: 24px !important;
  }
  .ml6-l {
    margin-left: 24px !important;
  }
  .mv6-l {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }
  .mh6-l {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }
  .p7-l {
    padding: 28px !important;
  }
  .pt7-l {
    padding-top: 28px !important;
  }
  .pr7-l {
    padding-right: 28px !important;
  }
  .pb7-l {
    padding-bottom: 28px !important;
  }
  .pl7-l {
    padding-left: 28px !important;
  }
  .pv7-l {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }
  .ph7-l {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }
  .m7-l {
    margin: 28px !important;
  }
  .mt7-l {
    margin-top: 28px !important;
  }
  .mr7-l {
    margin-right: 28px !important;
  }
  .mb7-l {
    margin-bottom: 28px !important;
  }
  .ml7-l {
    margin-left: 28px !important;
  }
  .mv7-l {
    margin-top: 28px !important;
    margin-bottom: 28px !important;
  }
  .mh7-l {
    margin-left: 28px !important;
    margin-right: 28px !important;
  }
  .p8-l {
    padding: 32px !important;
  }
  .pt8-l {
    padding-top: 32px !important;
  }
  .pr8-l {
    padding-right: 32px !important;
  }
  .pb8-l {
    padding-bottom: 32px !important;
  }
  .pl8-l {
    padding-left: 32px !important;
  }
  .pv8-l {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }
  .ph8-l {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }
  .m8-l {
    margin: 32px !important;
  }
  .mt8-l {
    margin-top: 32px !important;
  }
  .mr8-l {
    margin-right: 32px !important;
  }
  .mb8-l {
    margin-bottom: 32px !important;
  }
  .ml8-l {
    margin-left: 32px !important;
  }
  .mv8-l {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }
  .mh8-l {
    margin-left: 32px !important;
    margin-right: 32px !important;
  }
  .p9-l {
    padding: 36px !important;
  }
  .pt9-l {
    padding-top: 36px !important;
  }
  .pr9-l {
    padding-right: 36px !important;
  }
  .pb9-l {
    padding-bottom: 36px !important;
  }
  .pl9-l {
    padding-left: 36px !important;
  }
  .pv9-l {
    padding-top: 36px !important;
    padding-bottom: 36px !important;
  }
  .ph9-l {
    padding-left: 36px !important;
    padding-right: 36px !important;
  }
  .m9-l {
    margin: 36px !important;
  }
  .mt9-l {
    margin-top: 36px !important;
  }
  .mr9-l {
    margin-right: 36px !important;
  }
  .mb9-l {
    margin-bottom: 36px !important;
  }
  .ml9-l {
    margin-left: 36px !important;
  }
  .mv9-l {
    margin-top: 36px !important;
    margin-bottom: 36px !important;
  }
  .mh9-l {
    margin-left: 36px !important;
    margin-right: 36px !important;
  }
  .p3-d-l {
    padding: 48px !important;
  }
  .pt3-d-l {
    padding-top: 48px !important;
  }
  .pr3-d-l {
    padding-right: 48px !important;
  }
  .pb3-d-l {
    padding-bottom: 48px !important;
  }
  .pl3-d-l {
    padding-left: 48px !important;
  }
  .pv3-d-l {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }
  .ph3-d-l {
    padding-left: 48px !important;
    padding-right: 48px !important;
  }
  .m3-d-l {
    margin: 48px !important;
  }
  .mt3-d-l {
    margin-top: 48px !important;
  }
  .mr3-d-l {
    margin-right: 48px !important;
  }
  .mb3-d-l {
    margin-bottom: 48px !important;
  }
  .ml3-d-l {
    margin-left: 48px !important;
  }
  .mv3-d-l {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }
  .mh3-d-l {
    margin-left: 48px !important;
    margin-right: 48px !important;
  }
  .p4-d-l {
    padding: 64px !important;
  }
  .pt4-d-l {
    padding-top: 64px !important;
  }
  .pr4-d-l {
    padding-right: 64px !important;
  }
  .pb4-d-l {
    padding-bottom: 64px !important;
  }
  .pl4-d-l {
    padding-left: 64px !important;
  }
  .pv4-d-l {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }
  .ph4-d-l {
    padding-left: 64px !important;
    padding-right: 64px !important;
  }
  .m4-d-l {
    margin: 64px !important;
  }
  .mt4-d-l {
    margin-top: 64px !important;
  }
  .mr4-d-l {
    margin-right: 64px !important;
  }
  .mb4-d-l {
    margin-bottom: 64px !important;
  }
  .ml4-d-l {
    margin-left: 64px !important;
  }
  .mv4-d-l {
    margin-top: 64px !important;
    margin-bottom: 64px !important;
  }
  .mh4-d-l {
    margin-left: 64px !important;
    margin-right: 64px !important;
  }
  .p5-d-l {
    padding: 80px !important;
  }
  .pt5-d-l {
    padding-top: 80px !important;
  }
  .pr5-d-l {
    padding-right: 80px !important;
  }
  .pb5-d-l {
    padding-bottom: 80px !important;
  }
  .pl5-d-l {
    padding-left: 80px !important;
  }
  .pv5-d-l {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .ph5-d-l {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .m5-d-l {
    margin: 80px !important;
  }
  .mt5-d-l {
    margin-top: 80px !important;
  }
  .mr5-d-l {
    margin-right: 80px !important;
  }
  .mb5-d-l {
    margin-bottom: 80px !important;
  }
  .ml5-d-l {
    margin-left: 80px !important;
  }
  .mv5-d-l {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .mh5-d-l {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }
  .p6-d-l {
    padding: 96px !important;
  }
  .pt6-d-l {
    padding-top: 96px !important;
  }
  .pr6-d-l {
    padding-right: 96px !important;
  }
  .pb6-d-l {
    padding-bottom: 96px !important;
  }
  .pl6-d-l {
    padding-left: 96px !important;
  }
  .pv6-d-l {
    padding-top: 96px !important;
    padding-bottom: 96px !important;
  }
  .ph6-d-l {
    padding-left: 96px !important;
    padding-right: 96px !important;
  }
  .m6-d-l {
    margin: 96px !important;
  }
  .mt6-d-l {
    margin-top: 96px !important;
  }
  .mr6-d-l {
    margin-right: 96px !important;
  }
  .mb6-d-l {
    margin-bottom: 96px !important;
  }
  .ml6-d-l {
    margin-left: 96px !important;
  }
  .mv6-d-l {
    margin-top: 96px !important;
    margin-bottom: 96px !important;
  }
  .mh6-d-l {
    margin-left: 96px !important;
    margin-right: 96px !important;
  }
  .p7-d-l {
    padding: 112px !important;
  }
  .pt7-d-l {
    padding-top: 112px !important;
  }
  .pr7-d-l {
    padding-right: 112px !important;
  }
  .pb7-d-l {
    padding-bottom: 112px !important;
  }
  .pl7-d-l {
    padding-left: 112px !important;
  }
  .pv7-d-l {
    padding-top: 112px !important;
    padding-bottom: 112px !important;
  }
  .ph7-d-l {
    padding-left: 112px !important;
    padding-right: 112px !important;
  }
  .m7-d-l {
    margin: 112px !important;
  }
  .mt7-d-l {
    margin-top: 112px !important;
  }
  .mr7-d-l {
    margin-right: 112px !important;
  }
  .mb7-d-l {
    margin-bottom: 112px !important;
  }
  .ml7-d-l {
    margin-left: 112px !important;
  }
  .mv7-d-l {
    margin-top: 112px !important;
    margin-bottom: 112px !important;
  }
  .mh7-d-l {
    margin-left: 112px !important;
    margin-right: 112px !important;
  }
  .p8-d-l {
    padding: 128px !important;
  }
  .pt8-d-l {
    padding-top: 128px !important;
  }
  .pr8-d-l {
    padding-right: 128px !important;
  }
  .pb8-d-l {
    padding-bottom: 128px !important;
  }
  .pl8-d-l {
    padding-left: 128px !important;
  }
  .pv8-d-l {
    padding-top: 128px !important;
    padding-bottom: 128px !important;
  }
  .ph8-d-l {
    padding-left: 128px !important;
    padding-right: 128px !important;
  }
  .m8-d-l {
    margin: 128px !important;
  }
  .mt8-d-l {
    margin-top: 128px !important;
  }
  .mr8-d-l {
    margin-right: 128px !important;
  }
  .mb8-d-l {
    margin-bottom: 128px !important;
  }
  .ml8-d-l {
    margin-left: 128px !important;
  }
  .mv8-d-l {
    margin-top: 128px !important;
    margin-bottom: 128px !important;
  }
  .mh8-d-l {
    margin-left: 128px !important;
    margin-right: 128px !important;
  }
  .p9-d-l {
    padding: 144px !important;
  }
  .pt9-d-l {
    padding-top: 144px !important;
  }
  .pr9-d-l {
    padding-right: 144px !important;
  }
  .pb9-d-l {
    padding-bottom: 144px !important;
  }
  .pl9-d-l {
    padding-left: 144px !important;
  }
  .pv9-d-l {
    padding-top: 144px !important;
    padding-bottom: 144px !important;
  }
  .ph9-d-l {
    padding-left: 144px !important;
    padding-right: 144px !important;
  }
  .m9-d-l {
    margin: 144px !important;
  }
  .mt9-d-l {
    margin-top: 144px !important;
  }
  .mr9-d-l {
    margin-right: 144px !important;
  }
  .mb9-d-l {
    margin-bottom: 144px !important;
  }
  .ml9-d-l {
    margin-left: 144px !important;
  }
  .mv9-d-l {
    margin-top: 144px !important;
    margin-bottom: 144px !important;
  }
  .mh9-d-l {
    margin-left: 144px !important;
    margin-right: 144px !important;
  }
}
@media (min-width: 20em) {
  .p10-xs {
    padding: 10% !important;
  }
  .pt10-xs {
    padding-top: 10% !important;
  }
  .pr10-xs {
    padding-right: 10% !important;
  }
  .pb10-xs {
    padding-bottom: 10% !important;
  }
  .pl10-xs {
    padding-left: 10% !important;
  }
  .pv10-xs {
    padding-top: 10% !important;
    padding-bottom: 10% !important;
  }
  .ph10-xs {
    padding-left: 10% !important;
    padding-right: 10% !important;
  }
  .m10-xs {
    margin: 10% !important;
  }
  .mt10-xs {
    margin-top: 10% !important;
  }
  .mr10-xs {
    margin-right: 10% !important;
  }
  .mb10-xs {
    margin-bottom: 10% !important;
  }
  .ml10-xs {
    margin-left: 10% !important;
  }
  .mv10-xs {
    margin-top: 10% !important;
    margin-bottom: 10% !important;
  }
  .mh10-xs {
    margin-left: 10% !important;
    margin-right: 10% !important;
  }
  .p20-xs {
    padding: 20% !important;
  }
  .pt20-xs {
    padding-top: 20% !important;
  }
  .pr20-xs {
    padding-right: 20% !important;
  }
  .pb20-xs {
    padding-bottom: 20% !important;
  }
  .pl20-xs {
    padding-left: 20% !important;
  }
  .pv20-xs {
    padding-top: 20% !important;
    padding-bottom: 20% !important;
  }
  .ph20-xs {
    padding-left: 20% !important;
    padding-right: 20% !important;
  }
  .m20-xs {
    margin: 20% !important;
  }
  .mt20-xs {
    margin-top: 20% !important;
  }
  .mr20-xs {
    margin-right: 20% !important;
  }
  .mb20-xs {
    margin-bottom: 20% !important;
  }
  .ml20-xs {
    margin-left: 20% !important;
  }
  .mv20-xs {
    margin-top: 20% !important;
    margin-bottom: 20% !important;
  }
  .mh20-xs {
    margin-left: 20% !important;
    margin-right: 20% !important;
  }
  .p30-xs {
    padding: 30% !important;
  }
  .pt30-xs {
    padding-top: 30% !important;
  }
  .pr30-xs {
    padding-right: 30% !important;
  }
  .pb30-xs {
    padding-bottom: 30% !important;
  }
  .pl30-xs {
    padding-left: 30% !important;
  }
  .pv30-xs {
    padding-top: 30% !important;
    padding-bottom: 30% !important;
  }
  .ph30-xs {
    padding-left: 30% !important;
    padding-right: 30% !important;
  }
  .m30-xs {
    margin: 30% !important;
  }
  .mt30-xs {
    margin-top: 30% !important;
  }
  .mr30-xs {
    margin-right: 30% !important;
  }
  .mb30-xs {
    margin-bottom: 30% !important;
  }
  .ml30-xs {
    margin-left: 30% !important;
  }
  .mv30-xs {
    margin-top: 30% !important;
    margin-bottom: 30% !important;
  }
  .mh30-xs {
    margin-left: 30% !important;
    margin-right: 30% !important;
  }
  .p40-xs {
    padding: 40% !important;
  }
  .pt40-xs {
    padding-top: 40% !important;
  }
  .pr40-xs {
    padding-right: 40% !important;
  }
  .pb40-xs {
    padding-bottom: 40% !important;
  }
  .pl40-xs {
    padding-left: 40% !important;
  }
  .pv40-xs {
    padding-top: 40% !important;
    padding-bottom: 40% !important;
  }
  .ph40-xs {
    padding-left: 40% !important;
    padding-right: 40% !important;
  }
  .m40-xs {
    margin: 40% !important;
  }
  .mt40-xs {
    margin-top: 40% !important;
  }
  .mr40-xs {
    margin-right: 40% !important;
  }
  .mb40-xs {
    margin-bottom: 40% !important;
  }
  .ml40-xs {
    margin-left: 40% !important;
  }
  .mv40-xs {
    margin-top: 40% !important;
    margin-bottom: 40% !important;
  }
  .mh40-xs {
    margin-left: 40% !important;
    margin-right: 40% !important;
  }
  .p45-xs {
    padding: 45% !important;
  }
  .pt45-xs {
    padding-top: 45% !important;
  }
  .pr45-xs {
    padding-right: 45% !important;
  }
  .pb45-xs {
    padding-bottom: 45% !important;
  }
  .pl45-xs {
    padding-left: 45% !important;
  }
  .pv45-xs {
    padding-top: 45% !important;
    padding-bottom: 45% !important;
  }
  .ph45-xs {
    padding-left: 45% !important;
    padding-right: 45% !important;
  }
  .m45-xs {
    margin: 45% !important;
  }
  .mt45-xs {
    margin-top: 45% !important;
  }
  .mr45-xs {
    margin-right: 45% !important;
  }
  .mb45-xs {
    margin-bottom: 45% !important;
  }
  .ml45-xs {
    margin-left: 45% !important;
  }
  .mv45-xs {
    margin-top: 45% !important;
    margin-bottom: 45% !important;
  }
  .mh45-xs {
    margin-left: 45% !important;
    margin-right: 45% !important;
  }
  .p50-xs {
    padding: 50% !important;
  }
  .pt50-xs {
    padding-top: 50% !important;
  }
  .pr50-xs {
    padding-right: 50% !important;
  }
  .pb50-xs {
    padding-bottom: 50% !important;
  }
  .pl50-xs {
    padding-left: 50% !important;
  }
  .pv50-xs {
    padding-top: 50% !important;
    padding-bottom: 50% !important;
  }
  .ph50-xs {
    padding-left: 50% !important;
    padding-right: 50% !important;
  }
  .m50-xs {
    margin: 50% !important;
  }
  .mt50-xs {
    margin-top: 50% !important;
  }
  .mr50-xs {
    margin-right: 50% !important;
  }
  .mb50-xs {
    margin-bottom: 50% !important;
  }
  .ml50-xs {
    margin-left: 50% !important;
  }
  .mv50-xs {
    margin-top: 50% !important;
    margin-bottom: 50% !important;
  }
  .mh50-xs {
    margin-left: 50% !important;
    margin-right: 50% !important;
  }
  .p60-xs {
    padding: 60% !important;
  }
  .pt60-xs {
    padding-top: 60% !important;
  }
  .pr60-xs {
    padding-right: 60% !important;
  }
  .pb60-xs {
    padding-bottom: 60% !important;
  }
  .pl60-xs {
    padding-left: 60% !important;
  }
  .pv60-xs {
    padding-top: 60% !important;
    padding-bottom: 60% !important;
  }
  .ph60-xs {
    padding-left: 60% !important;
    padding-right: 60% !important;
  }
  .m60-xs {
    margin: 60% !important;
  }
  .mt60-xs {
    margin-top: 60% !important;
  }
  .mr60-xs {
    margin-right: 60% !important;
  }
  .mb60-xs {
    margin-bottom: 60% !important;
  }
  .ml60-xs {
    margin-left: 60% !important;
  }
  .mv60-xs {
    margin-top: 60% !important;
    margin-bottom: 60% !important;
  }
  .mh60-xs {
    margin-left: 60% !important;
    margin-right: 60% !important;
  }
  .p70-xs {
    padding: 70% !important;
  }
  .pt70-xs {
    padding-top: 70% !important;
  }
  .pr70-xs {
    padding-right: 70% !important;
  }
  .pb70-xs {
    padding-bottom: 70% !important;
  }
  .pl70-xs {
    padding-left: 70% !important;
  }
  .pv70-xs {
    padding-top: 70% !important;
    padding-bottom: 70% !important;
  }
  .ph70-xs {
    padding-left: 70% !important;
    padding-right: 70% !important;
  }
  .m70-xs {
    margin: 70% !important;
  }
  .mt70-xs {
    margin-top: 70% !important;
  }
  .mr70-xs {
    margin-right: 70% !important;
  }
  .mb70-xs {
    margin-bottom: 70% !important;
  }
  .ml70-xs {
    margin-left: 70% !important;
  }
  .mv70-xs {
    margin-top: 70% !important;
    margin-bottom: 70% !important;
  }
  .mh70-xs {
    margin-left: 70% !important;
    margin-right: 70% !important;
  }
  .p80-xs {
    padding: 80% !important;
  }
  .pt80-xs {
    padding-top: 80% !important;
  }
  .pr80-xs {
    padding-right: 80% !important;
  }
  .pb80-xs {
    padding-bottom: 80% !important;
  }
  .pl80-xs {
    padding-left: 80% !important;
  }
  .pv80-xs {
    padding-top: 80% !important;
    padding-bottom: 80% !important;
  }
  .ph80-xs {
    padding-left: 80% !important;
    padding-right: 80% !important;
  }
  .m80-xs {
    margin: 80% !important;
  }
  .mt80-xs {
    margin-top: 80% !important;
  }
  .mr80-xs {
    margin-right: 80% !important;
  }
  .mb80-xs {
    margin-bottom: 80% !important;
  }
  .ml80-xs {
    margin-left: 80% !important;
  }
  .mv80-xs {
    margin-top: 80% !important;
    margin-bottom: 80% !important;
  }
  .mh80-xs {
    margin-left: 80% !important;
    margin-right: 80% !important;
  }
  .p90-xs {
    padding: 90% !important;
  }
  .pt90-xs {
    padding-top: 90% !important;
  }
  .pr90-xs {
    padding-right: 90% !important;
  }
  .pb90-xs {
    padding-bottom: 90% !important;
  }
  .pl90-xs {
    padding-left: 90% !important;
  }
  .pv90-xs {
    padding-top: 90% !important;
    padding-bottom: 90% !important;
  }
  .ph90-xs {
    padding-left: 90% !important;
    padding-right: 90% !important;
  }
  .m90-xs {
    margin: 90% !important;
  }
  .mt90-xs {
    margin-top: 90% !important;
  }
  .mr90-xs {
    margin-right: 90% !important;
  }
  .mb90-xs {
    margin-bottom: 90% !important;
  }
  .ml90-xs {
    margin-left: 90% !important;
  }
  .mv90-xs {
    margin-top: 90% !important;
    margin-bottom: 90% !important;
  }
  .mh90-xs {
    margin-left: 90% !important;
    margin-right: 90% !important;
  }
  .p100-xs {
    padding: 100% !important;
  }
  .pt100-xs {
    padding-top: 100% !important;
  }
  .pr100-xs {
    padding-right: 100% !important;
  }
  .pb100-xs {
    padding-bottom: 100% !important;
  }
  .pl100-xs {
    padding-left: 100% !important;
  }
  .pv100-xs {
    padding-top: 100% !important;
    padding-bottom: 100% !important;
  }
  .ph100-xs {
    padding-left: 100% !important;
    padding-right: 100% !important;
  }
  .m100-xs {
    margin: 100% !important;
  }
  .mt100-xs {
    margin-top: 100% !important;
  }
  .mr100-xs {
    margin-right: 100% !important;
  }
  .mb100-xs {
    margin-bottom: 100% !important;
  }
  .ml100-xs {
    margin-left: 100% !important;
  }
  .mv100-xs {
    margin-top: 100% !important;
    margin-bottom: 100% !important;
  }
  .mh100-xs {
    margin-left: 100% !important;
    margin-right: 100% !important;
  }
  .pauto-xs {
    padding: auto !important;
  }
  .ptauto-xs {
    padding-top: auto !important;
  }
  .prauto-xs {
    padding-right: auto !important;
  }
  .pbauto-xs {
    padding-bottom: auto !important;
  }
  .plauto-xs {
    padding-left: auto !important;
  }
  .pvauto-xs {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
  .phauto-xs {
    padding-left: auto !important;
    padding-right: auto !important;
  }
  .mauto-xs {
    margin: auto !important;
  }
  .mtauto-xs {
    margin-top: auto !important;
  }
  .mrauto-xs {
    margin-right: auto !important;
  }
  .mbauto-xs {
    margin-bottom: auto !important;
  }
  .mlauto-xs {
    margin-left: auto !important;
  }
  .mvauto-xs {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mhauto-xs {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .p0-xs {
    padding: 0px !important;
  }
  .pt0-xs {
    padding-top: 0px !important;
  }
  .pr0-xs {
    padding-right: 0px !important;
  }
  .pb0-xs {
    padding-bottom: 0px !important;
  }
  .pl0-xs {
    padding-left: 0px !important;
  }
  .pv0-xs {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .ph0-xs {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .m0-xs {
    margin: 0px !important;
  }
  .mt0-xs {
    margin-top: 0px !important;
  }
  .mr0-xs {
    margin-right: 0px !important;
  }
  .mb0-xs {
    margin-bottom: 0px !important;
  }
  .ml0-xs {
    margin-left: 0px !important;
  }
  .mv0-xs {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .mh0-xs {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .p1-xs {
    padding: 4px !important;
  }
  .pt1-xs {
    padding-top: 4px !important;
  }
  .pr1-xs {
    padding-right: 4px !important;
  }
  .pb1-xs {
    padding-bottom: 4px !important;
  }
  .pl1-xs {
    padding-left: 4px !important;
  }
  .pv1-xs {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
  .ph1-xs {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
  .m1-xs {
    margin: 4px !important;
  }
  .mt1-xs {
    margin-top: 4px !important;
  }
  .mr1-xs {
    margin-right: 4px !important;
  }
  .mb1-xs {
    margin-bottom: 4px !important;
  }
  .ml1-xs {
    margin-left: 4px !important;
  }
  .mv1-xs {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }
  .mh1-xs {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }
  .p2-xs {
    padding: 8px !important;
  }
  .pt2-xs {
    padding-top: 8px !important;
  }
  .pr2-xs {
    padding-right: 8px !important;
  }
  .pb2-xs {
    padding-bottom: 8px !important;
  }
  .pl2-xs {
    padding-left: 8px !important;
  }
  .pv2-xs {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .ph2-xs {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .m2-xs {
    margin: 8px !important;
  }
  .mt2-xs {
    margin-top: 8px !important;
  }
  .mr2-xs {
    margin-right: 8px !important;
  }
  .mb2-xs {
    margin-bottom: 8px !important;
  }
  .ml2-xs {
    margin-left: 8px !important;
  }
  .mv2-xs {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .mh2-xs {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
  .p3-xs {
    padding: 12px !important;
  }
  .pt3-xs {
    padding-top: 12px !important;
  }
  .pr3-xs {
    padding-right: 12px !important;
  }
  .pb3-xs {
    padding-bottom: 12px !important;
  }
  .pl3-xs {
    padding-left: 12px !important;
  }
  .pv3-xs {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .ph3-xs {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .m3-xs {
    margin: 12px !important;
  }
  .mt3-xs {
    margin-top: 12px !important;
  }
  .mr3-xs {
    margin-right: 12px !important;
  }
  .mb3-xs {
    margin-bottom: 12px !important;
  }
  .ml3-xs {
    margin-left: 12px !important;
  }
  .mv3-xs {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .mh3-xs {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }
  .p4-xs {
    padding: 16px !important;
  }
  .pt4-xs {
    padding-top: 16px !important;
  }
  .pr4-xs {
    padding-right: 16px !important;
  }
  .pb4-xs {
    padding-bottom: 16px !important;
  }
  .pl4-xs {
    padding-left: 16px !important;
  }
  .pv4-xs {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  .ph4-xs {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .m4-xs {
    margin: 16px !important;
  }
  .mt4-xs {
    margin-top: 16px !important;
  }
  .mr4-xs {
    margin-right: 16px !important;
  }
  .mb4-xs {
    margin-bottom: 16px !important;
  }
  .ml4-xs {
    margin-left: 16px !important;
  }
  .mv4-xs {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  .mh4-xs {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }
  .p5-xs {
    padding: 20px !important;
  }
  .pt5-xs {
    padding-top: 20px !important;
  }
  .pr5-xs {
    padding-right: 20px !important;
  }
  .pb5-xs {
    padding-bottom: 20px !important;
  }
  .pl5-xs {
    padding-left: 20px !important;
  }
  .pv5-xs {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .ph5-xs {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .m5-xs {
    margin: 20px !important;
  }
  .mt5-xs {
    margin-top: 20px !important;
  }
  .mr5-xs {
    margin-right: 20px !important;
  }
  .mb5-xs {
    margin-bottom: 20px !important;
  }
  .ml5-xs {
    margin-left: 20px !important;
  }
  .mv5-xs {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .mh5-xs {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .p6-xs {
    padding: 24px !important;
  }
  .pt6-xs {
    padding-top: 24px !important;
  }
  .pr6-xs {
    padding-right: 24px !important;
  }
  .pb6-xs {
    padding-bottom: 24px !important;
  }
  .pl6-xs {
    padding-left: 24px !important;
  }
  .pv6-xs {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
  .ph6-xs {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .m6-xs {
    margin: 24px !important;
  }
  .mt6-xs {
    margin-top: 24px !important;
  }
  .mr6-xs {
    margin-right: 24px !important;
  }
  .mb6-xs {
    margin-bottom: 24px !important;
  }
  .ml6-xs {
    margin-left: 24px !important;
  }
  .mv6-xs {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }
  .mh6-xs {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }
  .p7-xs {
    padding: 28px !important;
  }
  .pt7-xs {
    padding-top: 28px !important;
  }
  .pr7-xs {
    padding-right: 28px !important;
  }
  .pb7-xs {
    padding-bottom: 28px !important;
  }
  .pl7-xs {
    padding-left: 28px !important;
  }
  .pv7-xs {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }
  .ph7-xs {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }
  .m7-xs {
    margin: 28px !important;
  }
  .mt7-xs {
    margin-top: 28px !important;
  }
  .mr7-xs {
    margin-right: 28px !important;
  }
  .mb7-xs {
    margin-bottom: 28px !important;
  }
  .ml7-xs {
    margin-left: 28px !important;
  }
  .mv7-xs {
    margin-top: 28px !important;
    margin-bottom: 28px !important;
  }
  .mh7-xs {
    margin-left: 28px !important;
    margin-right: 28px !important;
  }
  .p8-xs {
    padding: 32px !important;
  }
  .pt8-xs {
    padding-top: 32px !important;
  }
  .pr8-xs {
    padding-right: 32px !important;
  }
  .pb8-xs {
    padding-bottom: 32px !important;
  }
  .pl8-xs {
    padding-left: 32px !important;
  }
  .pv8-xs {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }
  .ph8-xs {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }
  .m8-xs {
    margin: 32px !important;
  }
  .mt8-xs {
    margin-top: 32px !important;
  }
  .mr8-xs {
    margin-right: 32px !important;
  }
  .mb8-xs {
    margin-bottom: 32px !important;
  }
  .ml8-xs {
    margin-left: 32px !important;
  }
  .mv8-xs {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }
  .mh8-xs {
    margin-left: 32px !important;
    margin-right: 32px !important;
  }
  .p9-xs {
    padding: 36px !important;
  }
  .pt9-xs {
    padding-top: 36px !important;
  }
  .pr9-xs {
    padding-right: 36px !important;
  }
  .pb9-xs {
    padding-bottom: 36px !important;
  }
  .pl9-xs {
    padding-left: 36px !important;
  }
  .pv9-xs {
    padding-top: 36px !important;
    padding-bottom: 36px !important;
  }
  .ph9-xs {
    padding-left: 36px !important;
    padding-right: 36px !important;
  }
  .m9-xs {
    margin: 36px !important;
  }
  .mt9-xs {
    margin-top: 36px !important;
  }
  .mr9-xs {
    margin-right: 36px !important;
  }
  .mb9-xs {
    margin-bottom: 36px !important;
  }
  .ml9-xs {
    margin-left: 36px !important;
  }
  .mv9-xs {
    margin-top: 36px !important;
    margin-bottom: 36px !important;
  }
  .mh9-xs {
    margin-left: 36px !important;
    margin-right: 36px !important;
  }
  .p3-d-xs {
    padding: 48px !important;
  }
  .pt3-d-xs {
    padding-top: 48px !important;
  }
  .pr3-d-xs {
    padding-right: 48px !important;
  }
  .pb3-d-xs {
    padding-bottom: 48px !important;
  }
  .pl3-d-xs {
    padding-left: 48px !important;
  }
  .pv3-d-xs {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }
  .ph3-d-xs {
    padding-left: 48px !important;
    padding-right: 48px !important;
  }
  .m3-d-xs {
    margin: 48px !important;
  }
  .mt3-d-xs {
    margin-top: 48px !important;
  }
  .mr3-d-xs {
    margin-right: 48px !important;
  }
  .mb3-d-xs {
    margin-bottom: 48px !important;
  }
  .ml3-d-xs {
    margin-left: 48px !important;
  }
  .mv3-d-xs {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }
  .mh3-d-xs {
    margin-left: 48px !important;
    margin-right: 48px !important;
  }
  .p4-d-xs {
    padding: 64px !important;
  }
  .pt4-d-xs {
    padding-top: 64px !important;
  }
  .pr4-d-xs {
    padding-right: 64px !important;
  }
  .pb4-d-xs {
    padding-bottom: 64px !important;
  }
  .pl4-d-xs {
    padding-left: 64px !important;
  }
  .pv4-d-xs {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }
  .ph4-d-xs {
    padding-left: 64px !important;
    padding-right: 64px !important;
  }
  .m4-d-xs {
    margin: 64px !important;
  }
  .mt4-d-xs {
    margin-top: 64px !important;
  }
  .mr4-d-xs {
    margin-right: 64px !important;
  }
  .mb4-d-xs {
    margin-bottom: 64px !important;
  }
  .ml4-d-xs {
    margin-left: 64px !important;
  }
  .mv4-d-xs {
    margin-top: 64px !important;
    margin-bottom: 64px !important;
  }
  .mh4-d-xs {
    margin-left: 64px !important;
    margin-right: 64px !important;
  }
  .p5-d-xs {
    padding: 80px !important;
  }
  .pt5-d-xs {
    padding-top: 80px !important;
  }
  .pr5-d-xs {
    padding-right: 80px !important;
  }
  .pb5-d-xs {
    padding-bottom: 80px !important;
  }
  .pl5-d-xs {
    padding-left: 80px !important;
  }
  .pv5-d-xs {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .ph5-d-xs {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .m5-d-xs {
    margin: 80px !important;
  }
  .mt5-d-xs {
    margin-top: 80px !important;
  }
  .mr5-d-xs {
    margin-right: 80px !important;
  }
  .mb5-d-xs {
    margin-bottom: 80px !important;
  }
  .ml5-d-xs {
    margin-left: 80px !important;
  }
  .mv5-d-xs {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .mh5-d-xs {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }
  .p6-d-xs {
    padding: 96px !important;
  }
  .pt6-d-xs {
    padding-top: 96px !important;
  }
  .pr6-d-xs {
    padding-right: 96px !important;
  }
  .pb6-d-xs {
    padding-bottom: 96px !important;
  }
  .pl6-d-xs {
    padding-left: 96px !important;
  }
  .pv6-d-xs {
    padding-top: 96px !important;
    padding-bottom: 96px !important;
  }
  .ph6-d-xs {
    padding-left: 96px !important;
    padding-right: 96px !important;
  }
  .m6-d-xs {
    margin: 96px !important;
  }
  .mt6-d-xs {
    margin-top: 96px !important;
  }
  .mr6-d-xs {
    margin-right: 96px !important;
  }
  .mb6-d-xs {
    margin-bottom: 96px !important;
  }
  .ml6-d-xs {
    margin-left: 96px !important;
  }
  .mv6-d-xs {
    margin-top: 96px !important;
    margin-bottom: 96px !important;
  }
  .mh6-d-xs {
    margin-left: 96px !important;
    margin-right: 96px !important;
  }
  .p7-d-xs {
    padding: 112px !important;
  }
  .pt7-d-xs {
    padding-top: 112px !important;
  }
  .pr7-d-xs {
    padding-right: 112px !important;
  }
  .pb7-d-xs {
    padding-bottom: 112px !important;
  }
  .pl7-d-xs {
    padding-left: 112px !important;
  }
  .pv7-d-xs {
    padding-top: 112px !important;
    padding-bottom: 112px !important;
  }
  .ph7-d-xs {
    padding-left: 112px !important;
    padding-right: 112px !important;
  }
  .m7-d-xs {
    margin: 112px !important;
  }
  .mt7-d-xs {
    margin-top: 112px !important;
  }
  .mr7-d-xs {
    margin-right: 112px !important;
  }
  .mb7-d-xs {
    margin-bottom: 112px !important;
  }
  .ml7-d-xs {
    margin-left: 112px !important;
  }
  .mv7-d-xs {
    margin-top: 112px !important;
    margin-bottom: 112px !important;
  }
  .mh7-d-xs {
    margin-left: 112px !important;
    margin-right: 112px !important;
  }
  .p8-d-xs {
    padding: 128px !important;
  }
  .pt8-d-xs {
    padding-top: 128px !important;
  }
  .pr8-d-xs {
    padding-right: 128px !important;
  }
  .pb8-d-xs {
    padding-bottom: 128px !important;
  }
  .pl8-d-xs {
    padding-left: 128px !important;
  }
  .pv8-d-xs {
    padding-top: 128px !important;
    padding-bottom: 128px !important;
  }
  .ph8-d-xs {
    padding-left: 128px !important;
    padding-right: 128px !important;
  }
  .m8-d-xs {
    margin: 128px !important;
  }
  .mt8-d-xs {
    margin-top: 128px !important;
  }
  .mr8-d-xs {
    margin-right: 128px !important;
  }
  .mb8-d-xs {
    margin-bottom: 128px !important;
  }
  .ml8-d-xs {
    margin-left: 128px !important;
  }
  .mv8-d-xs {
    margin-top: 128px !important;
    margin-bottom: 128px !important;
  }
  .mh8-d-xs {
    margin-left: 128px !important;
    margin-right: 128px !important;
  }
  .p9-d-xs {
    padding: 144px !important;
  }
  .pt9-d-xs {
    padding-top: 144px !important;
  }
  .pr9-d-xs {
    padding-right: 144px !important;
  }
  .pb9-d-xs {
    padding-bottom: 144px !important;
  }
  .pl9-d-xs {
    padding-left: 144px !important;
  }
  .pv9-d-xs {
    padding-top: 144px !important;
    padding-bottom: 144px !important;
  }
  .ph9-d-xs {
    padding-left: 144px !important;
    padding-right: 144px !important;
  }
  .m9-d-xs {
    margin: 144px !important;
  }
  .mt9-d-xs {
    margin-top: 144px !important;
  }
  .mr9-d-xs {
    margin-right: 144px !important;
  }
  .mb9-d-xs {
    margin-bottom: 144px !important;
  }
  .ml9-d-xs {
    margin-left: 144px !important;
  }
  .mv9-d-xs {
    margin-top: 144px !important;
    margin-bottom: 144px !important;
  }
  .mh9-d-xs {
    margin-left: 144px !important;
    margin-right: 144px !important;
  }
}
.u-text-center {
  text-align: center !important;
}
.u-text-left {
  text-align: left !important;
}
.u-text-right {
  text-align: right !important;
}
.u-word-break {
  word-break: break-word !important;
}
@media (min-width: 0) {
  .u-text-center-xs {
    text-align: center !important;
  }
  .u-text-left-xs {
    text-align: left !important;
  }
  .u-text-right-xs {
    text-align: right !important;
  }
  .u-word-break-xs {
    word-break: break-word !important;
  }
}
@media (min-width: 576px) {
  .u-text-center-sm {
    text-align: center !important;
  }
  .u-text-left-sm {
    text-align: left !important;
  }
  .u-text-right-sm {
    text-align: right !important;
  }
  .u-word-break-sm {
    word-break: break-word !important;
  }
}
@media (min-width: 768px) {
  .u-text-center-md {
    text-align: center !important;
  }
  .u-text-left-md {
    text-align: left !important;
  }
  .u-text-right-md {
    text-align: right !important;
  }
  .u-word-break-md {
    word-break: break-word !important;
  }
}
@media (min-width: 992px) {
  .u-text-center-lg {
    text-align: center !important;
  }
  .u-text-left-lg {
    text-align: left !important;
  }
  .u-text-right-lg {
    text-align: right !important;
  }
  .u-word-break-lg {
    word-break: break-word !important;
  }
}
@media (min-width: 1200px) {
  .u-text-center-xl {
    text-align: center !important;
  }
  .u-text-left-xl {
    text-align: left !important;
  }
  .u-text-right-xl {
    text-align: right !important;
  }
  .u-word-break-xl {
    word-break: break-word !important;
  }
}
@media (min-width: 1400px) {
  .u-text-center-xxl {
    text-align: center !important;
  }
  .u-text-left-xxl {
    text-align: left !important;
  }
  .u-text-right-xxl {
    text-align: right !important;
  }
  .u-word-break-xxl {
    word-break: break-word !important;
  }
}
.u-text-transform {
  text-transform: capitalize;
}
.z-index-0 {
  z-index: 0;
}
.z-index-1 {
  z-index: 1;
}
.z-index-2 {
  z-index: 2;
}
.z-index-3 {
  z-index: 3;
}
.z-index-pre-max {
  z-index: 9999;
}
.z-index-modal {
  z-index: 100000;
}
.z-index-select-option {
  z-index: 100001;
}
.z-index--1 {
  z-index: -1;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.pointer {
  cursor: pointer;
}
.u-w-10 {
  width: 10% !important;
}
.u-h-10 {
  height: 10% !important;
}
.u-w-20 {
  width: 20% !important;
}
.u-h-20 {
  height: 20% !important;
}
.u-w-30 {
  width: 30% !important;
}
.u-h-30 {
  height: 30% !important;
}
.u-w-40 {
  width: 40% !important;
}
.u-h-40 {
  height: 40% !important;
}
.u-w-45 {
  width: 45% !important;
}
.u-h-45 {
  height: 45% !important;
}
.u-w-48 {
  width: 48% !important;
}
.u-h-48 {
  height: 48% !important;
}
.u-w-50 {
  width: 50% !important;
}
.u-h-50 {
  height: 50% !important;
}
.u-w-60 {
  width: 60% !important;
}
.u-h-60 {
  height: 60% !important;
}
.u-w-70 {
  width: 70% !important;
}
.u-h-70 {
  height: 70% !important;
}
.u-w-80 {
  width: 80% !important;
}
.u-h-80 {
  height: 80% !important;
}
.u-w-90 {
  width: 90% !important;
}
.u-h-90 {
  height: 90% !important;
}
.u-w-100 {
  width: 100% !important;
}
.u-h-100 {
  height: 100% !important;
}
@media (min-width: 0) {
  .u-w-10-xs {
    width: 10% !important;
  }
  .u-h-10-xs {
    height: 10% !important;
  }
  .u-w-20-xs {
    width: 20% !important;
  }
  .u-h-20-xs {
    height: 20% !important;
  }
  .u-w-30-xs {
    width: 30% !important;
  }
  .u-h-30-xs {
    height: 30% !important;
  }
  .u-w-40-xs {
    width: 40% !important;
  }
  .u-h-40-xs {
    height: 40% !important;
  }
  .u-w-45-xs {
    width: 45% !important;
  }
  .u-h-45-xs {
    height: 45% !important;
  }
  .u-w-48-xs {
    width: 48% !important;
  }
  .u-h-48-xs {
    height: 48% !important;
  }
  .u-w-50-xs {
    width: 50% !important;
  }
  .u-h-50-xs {
    height: 50% !important;
  }
  .u-w-60-xs {
    width: 60% !important;
  }
  .u-h-60-xs {
    height: 60% !important;
  }
  .u-w-70-xs {
    width: 70% !important;
  }
  .u-h-70-xs {
    height: 70% !important;
  }
  .u-w-80-xs {
    width: 80% !important;
  }
  .u-h-80-xs {
    height: 80% !important;
  }
  .u-w-90-xs {
    width: 90% !important;
  }
  .u-h-90-xs {
    height: 90% !important;
  }
  .u-w-100-xs {
    width: 100% !important;
  }
  .u-h-100-xs {
    height: 100% !important;
  }
}
@media (min-width: 576px) {
  .u-w-10-sm {
    width: 10% !important;
  }
  .u-h-10-sm {
    height: 10% !important;
  }
  .u-w-20-sm {
    width: 20% !important;
  }
  .u-h-20-sm {
    height: 20% !important;
  }
  .u-w-30-sm {
    width: 30% !important;
  }
  .u-h-30-sm {
    height: 30% !important;
  }
  .u-w-40-sm {
    width: 40% !important;
  }
  .u-h-40-sm {
    height: 40% !important;
  }
  .u-w-45-sm {
    width: 45% !important;
  }
  .u-h-45-sm {
    height: 45% !important;
  }
  .u-w-48-sm {
    width: 48% !important;
  }
  .u-h-48-sm {
    height: 48% !important;
  }
  .u-w-50-sm {
    width: 50% !important;
  }
  .u-h-50-sm {
    height: 50% !important;
  }
  .u-w-60-sm {
    width: 60% !important;
  }
  .u-h-60-sm {
    height: 60% !important;
  }
  .u-w-70-sm {
    width: 70% !important;
  }
  .u-h-70-sm {
    height: 70% !important;
  }
  .u-w-80-sm {
    width: 80% !important;
  }
  .u-h-80-sm {
    height: 80% !important;
  }
  .u-w-90-sm {
    width: 90% !important;
  }
  .u-h-90-sm {
    height: 90% !important;
  }
  .u-w-100-sm {
    width: 100% !important;
  }
  .u-h-100-sm {
    height: 100% !important;
  }
}
@media (min-width: 768px) {
  .u-w-10-md {
    width: 10% !important;
  }
  .u-h-10-md {
    height: 10% !important;
  }
  .u-w-20-md {
    width: 20% !important;
  }
  .u-h-20-md {
    height: 20% !important;
  }
  .u-w-30-md {
    width: 30% !important;
  }
  .u-h-30-md {
    height: 30% !important;
  }
  .u-w-40-md {
    width: 40% !important;
  }
  .u-h-40-md {
    height: 40% !important;
  }
  .u-w-45-md {
    width: 45% !important;
  }
  .u-h-45-md {
    height: 45% !important;
  }
  .u-w-48-md {
    width: 48% !important;
  }
  .u-h-48-md {
    height: 48% !important;
  }
  .u-w-50-md {
    width: 50% !important;
  }
  .u-h-50-md {
    height: 50% !important;
  }
  .u-w-60-md {
    width: 60% !important;
  }
  .u-h-60-md {
    height: 60% !important;
  }
  .u-w-70-md {
    width: 70% !important;
  }
  .u-h-70-md {
    height: 70% !important;
  }
  .u-w-80-md {
    width: 80% !important;
  }
  .u-h-80-md {
    height: 80% !important;
  }
  .u-w-90-md {
    width: 90% !important;
  }
  .u-h-90-md {
    height: 90% !important;
  }
  .u-w-100-md {
    width: 100% !important;
  }
  .u-h-100-md {
    height: 100% !important;
  }
}
@media (min-width: 992px) {
  .u-w-10-lg {
    width: 10% !important;
  }
  .u-h-10-lg {
    height: 10% !important;
  }
  .u-w-20-lg {
    width: 20% !important;
  }
  .u-h-20-lg {
    height: 20% !important;
  }
  .u-w-30-lg {
    width: 30% !important;
  }
  .u-h-30-lg {
    height: 30% !important;
  }
  .u-w-40-lg {
    width: 40% !important;
  }
  .u-h-40-lg {
    height: 40% !important;
  }
  .u-w-45-lg {
    width: 45% !important;
  }
  .u-h-45-lg {
    height: 45% !important;
  }
  .u-w-48-lg {
    width: 48% !important;
  }
  .u-h-48-lg {
    height: 48% !important;
  }
  .u-w-50-lg {
    width: 50% !important;
  }
  .u-h-50-lg {
    height: 50% !important;
  }
  .u-w-60-lg {
    width: 60% !important;
  }
  .u-h-60-lg {
    height: 60% !important;
  }
  .u-w-70-lg {
    width: 70% !important;
  }
  .u-h-70-lg {
    height: 70% !important;
  }
  .u-w-80-lg {
    width: 80% !important;
  }
  .u-h-80-lg {
    height: 80% !important;
  }
  .u-w-90-lg {
    width: 90% !important;
  }
  .u-h-90-lg {
    height: 90% !important;
  }
  .u-w-100-lg {
    width: 100% !important;
  }
  .u-h-100-lg {
    height: 100% !important;
  }
}
@media (min-width: 1200px) {
  .u-w-10-xl {
    width: 10% !important;
  }
  .u-h-10-xl {
    height: 10% !important;
  }
  .u-w-20-xl {
    width: 20% !important;
  }
  .u-h-20-xl {
    height: 20% !important;
  }
  .u-w-30-xl {
    width: 30% !important;
  }
  .u-h-30-xl {
    height: 30% !important;
  }
  .u-w-40-xl {
    width: 40% !important;
  }
  .u-h-40-xl {
    height: 40% !important;
  }
  .u-w-45-xl {
    width: 45% !important;
  }
  .u-h-45-xl {
    height: 45% !important;
  }
  .u-w-48-xl {
    width: 48% !important;
  }
  .u-h-48-xl {
    height: 48% !important;
  }
  .u-w-50-xl {
    width: 50% !important;
  }
  .u-h-50-xl {
    height: 50% !important;
  }
  .u-w-60-xl {
    width: 60% !important;
  }
  .u-h-60-xl {
    height: 60% !important;
  }
  .u-w-70-xl {
    width: 70% !important;
  }
  .u-h-70-xl {
    height: 70% !important;
  }
  .u-w-80-xl {
    width: 80% !important;
  }
  .u-h-80-xl {
    height: 80% !important;
  }
  .u-w-90-xl {
    width: 90% !important;
  }
  .u-h-90-xl {
    height: 90% !important;
  }
  .u-w-100-xl {
    width: 100% !important;
  }
  .u-h-100-xl {
    height: 100% !important;
  }
}
@media (min-width: 1400px) {
  .u-w-10-xxl {
    width: 10% !important;
  }
  .u-h-10-xxl {
    height: 10% !important;
  }
  .u-w-20-xxl {
    width: 20% !important;
  }
  .u-h-20-xxl {
    height: 20% !important;
  }
  .u-w-30-xxl {
    width: 30% !important;
  }
  .u-h-30-xxl {
    height: 30% !important;
  }
  .u-w-40-xxl {
    width: 40% !important;
  }
  .u-h-40-xxl {
    height: 40% !important;
  }
  .u-w-45-xxl {
    width: 45% !important;
  }
  .u-h-45-xxl {
    height: 45% !important;
  }
  .u-w-48-xxl {
    width: 48% !important;
  }
  .u-h-48-xxl {
    height: 48% !important;
  }
  .u-w-50-xxl {
    width: 50% !important;
  }
  .u-h-50-xxl {
    height: 50% !important;
  }
  .u-w-60-xxl {
    width: 60% !important;
  }
  .u-h-60-xxl {
    height: 60% !important;
  }
  .u-w-70-xxl {
    width: 70% !important;
  }
  .u-h-70-xxl {
    height: 70% !important;
  }
  .u-w-80-xxl {
    width: 80% !important;
  }
  .u-h-80-xxl {
    height: 80% !important;
  }
  .u-w-90-xxl {
    width: 90% !important;
  }
  .u-h-90-xxl {
    height: 90% !important;
  }
  .u-w-100-xxl {
    width: 100% !important;
  }
  .u-h-100-xxl {
    height: 100% !important;
  }
}
/* ==================
          文本
 ==================== */
.text-xs {
  font-size: 20upx;
}
.text-sm {
  font-size: 24upx;
}
.text-df {
  font-size: 28upx;
}
.text-lg {
  font-size: 32upx;
}
.text-xl {
  font-size: 36upx;
}
.text-xxl {
  font-size: 44upx;
}
.text-xxxl {
  font-size: 52upx;
}
.text-sl {
  font-size: 80upx;
}
.text-xsl {
  font-size: 120upx;
}
/*  -- flex弹性布局 -- */
.flex {
  display: flex;
}
.basis-xs {
  flex-basis: 20%;
}
.basis-sm {
  flex-basis: 40%;
}
.basis-df {
  flex-basis: 50%;
}
.basis-lg {
  flex-basis: 60%;
}
.basis-xl {
  flex-basis: 80%;
}
.flex-sub {
  flex: 1;
}
.flex-twice {
  flex: 2;
}
.flex-treble {
  flex: 3;
}
.flex-direction {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}
.align-center {
  align-items: center;
}
.align-stretch {
  align-items: stretch;
}
.self-start {
  align-self: flex-start;
}
.self-center {
  align-self: flex-center;
}
.self-end {
  align-self: flex-end;
}
.self-stretch {
  align-self: stretch;
}
.align-stretch {
  align-items: stretch;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.p__home {
  color: #363a3f;
}
.p__home .banner {
  width: 100%;
  height: 720px;
  background: url("/assets/img/banner.jpg") no-repeat;
  background-size: cover;
}
.p__home .banner .head_left img {
  width: 90px;
}
.p__home .banner .head_right img {
  width: 32px;
}
.p__home .banner .head_title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 650px;
}
.p__home .container {
  width: 1200px;
  margin: 0 auto;
}
.p__home .intor_desc {
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  width: 908px;
  margin: 0 auto;
}
.p__home .intro {
  margin-top: 48px;
  display: flex;
}
.p__home .intro img {
  width: 600px;
}
.p__home .intro .info {
  padding-left: 56px;
  font-size: 20px;
  line-height: 28px;
  color: #222222;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.p__home .intro .info p {
  margin: 12px 0;
}
.p__home .hr {
  margin-top: 96px;
  width: 100%;
  height: 8px;
  background: #f7f7f7;
}
.p__home .code {
  align-items: center;
  justify-content: center;
}
.p__home .code_l img {
  width: 280px;
}
.p__home .code_c {
  margin-left: 100px;
}
.p__home .code_c img {
  width: 56px;
}
.p__home .code_r img {
  width: 460px;
}
.footer {
  width: 100%;
  height: 120px;
  background: #171e26;
  font-size: 13px;
  line-height: 120px;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
}
.footer a {
  margin-left: 10px;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.8);
}
