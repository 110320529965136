.spacing(){
  auto:auto !important;
  0: 0px !important;
  1: 4px !important;
  2: 8px !important;
  3: 12px !important;
  4: 16px !important;
  5: 20px !important;
  6: 24px !important;
  7: 28px !important;
  8: 32px !important;
  9: 36px !important; 
  3-d:48px !important;
  4-d:64px !important;
  5-d:80px !important;
  6-d:96px !important;
  7-d:112px !important;
  8-d:128px !important;
  9-d:144px !important;
}
// 4的倍数
// d: 16的倍数
  each(.spacing(),.(@v,@k,@i){
    .p@{k}{padding:@v !important}
    .pt@{k}{padding-top:@v !important}
    .pr@{k}{padding-right:@v !important}
    .pb@{k}{padding-bottom:@v !important}
    .pl@{k}{padding-left:@v !important}
    .pv@{k}{padding-top:@v !important;padding-bottom:@v !important;}
    .ph@{k}{padding-left:@v !important;padding-right:@v !important;}
    
    .m@{k}{margin:@v !important}
    .mt@{k}{margin-top:@v !important}
    .mr@{k}{margin-right:@v !important}
    .mb@{k}{margin-bottom:@v !important}
    .ml@{k}{margin-left:@v !important}
    .mv@{k}{margin-top:@v !important;margin-bottom:@v !important;}
    .mh@{k}{margin-left:@v !important;margin-right:@v !important;}
    .mh-auto{margin: 0 auto}
  })
each(@breakpoint,{
  @media (min-width: @value){
    each(.spacing(),.(@v,@k,@i){
      .p@{k}-@{key}{padding:@v !important};
      .pt@{k}-@{key}{padding-top:@v !important}
      .pr@{k}-@{key}{padding-right:@v !important}
      .pb@{k}-@{key}{padding-bottom:@v !important}
      .pl@{k}-@{key}{padding-left:@v !important}
      .pv@{k}-@{key}{padding-top:@v !important;padding-bottom:@v !important;}
      .ph@{k}-@{key}{padding-left:@v !important;padding-right:@v !important;}

      .m@{k}-@{key}{margin:@v !important}
    .mt@{k}-@{key}{margin-top:@v !important}
    .mr@{k}-@{key}{margin-right:@v !important}
    .mb@{k}-@{key}{margin-bottom:@v !important}
    .ml@{k}-@{key}{margin-left:@v !important}
    .mv@{k}-@{key}{margin-top:@v !important;margin-bottom:@v !important;}
    .mh@{k}-@{key}{margin-left:@v !important;margin-right:@v !important;}
    })
  }
})