.infix() {
  10: 10%;
  20: 20%;
  30: 30%;
  40: 40%;
  45:45%;
  48: 48%;
  50: 50%;
  60: 60%;
  70: 70%;
  80: 80%;
  90: 90%;
  100: 100%;
}
each(.infix(),.(@v,@k,@i){
  .u-w-@{k}{width:@v !important}
  .u-h-@{k}{height:@v !important}
})
each(@breakpoints,{
  @media (min-width: @value){
    each(.infix(),.(@v,@k,@i){
      .u-w-@{k}-@{key}{width:@v !important}
      .u-h-@{k}-@{key}{height:@v !important}
    })
  }
  });
