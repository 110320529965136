// z-index
@z-index-0: 0;
@z-index-1: 1;
@z-index-2: 2;
@z-index-3: 3;
@z-index-pre-max: 9999;
@z-index-max: 10000;
@z-index-modal: 100000;
@z-index-select-option: 100001;

.z-index-0{z-index: @z-index-0;}
.z-index-1{z-index: @z-index-1;}
.z-index-2{z-index: @z-index-2;}
.z-index-3{z-index: @z-index-3;}
.z-index-pre-max{z-index: @z-index-pre-max;}
.z-index-modal{z-index: @z-index-modal;}
.z-index-select-option{z-index: @z-index-select-option;}

.z-index--1{z-index:-1}
.relative{
  position: relative
}

.absolute{
  position:absolute;
}

.pointer{cursor: pointer;}