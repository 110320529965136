.fw6 {
  font-weight: 600;
}
.bold {
  font-weight: bold;
}
.fw5 {
  font-weight: 500;
}
.fw4 {
  font-weight: 400;
}
.normal {
  font-weight: normal;
}
.fw3 {
  font-weight: 300;
}

.lh1 {
  line-height: 150%;
}
.lh2 {
  line-height: 125%;
}