.u-text-center {
  text-align: center !important;
}

.u-text-left {
  text-align: left !important;
}

.u-text-right {
  text-align: right !important;
}

.u-word-break {
  word-break: break-word !important;
}


each(@breakpoints,{
  @media (min-width: @value){
    .u-text-center-@{key} {
      text-align: center !important;
    }
    
    .u-text-left-@{key} {
      text-align: left !important;
    }
    
    .u-text-right-@{key} {
      text-align: right !important;
    }
    
    .u-word-break-@{key} {
      word-break: break-word !important;
    }
  }
})
// 首字母大写
.u-text-transform{
  text-transform: capitalize;
}