
@primary: #da151f;
@secondary: #db5656;
@light-red:#fce7e7;
@gray: #666666;
@light-gray: #999999;
@dark-gray: #222222;
@white: white;

@bd-gray: #eeeeee;
@bd-gray-dark: #dddddd;
@bg-red:#fce7e7;
@theme-clrs: {
  primary: @primary;
  secondary: @secondary;
  gray: @gray;
  light-gray: @light-gray;
  dark-gray: @dark-gray;
  white: @white;
};

each(@theme-clrs, {
  .u-color-@{key}{
    color : @value !important;
  }
  .u-b-color-@{key}{
    background-color : @value !important;
  }
});
