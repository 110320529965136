.p__home {
  color: #363a3f;
  .banner {
    width: 100%;
    height: 720px;
    background: url("/assets/img/banner.jpg") no-repeat;
    background-size: cover;
    .head_left img {
      width: 90px;
    }
    .head_right img {
      width: 32px;
    }
    .head_title {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 650px;
    }
  }
  .container {
    width: 1200px;
    margin: 0 auto;
  }
  .intor_desc {
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    width: 908px;
    margin: 0 auto;
  }
  .intro {
    margin-top: 48px;
    display: flex;
    img {
      width: 600px;
    }
    .info {
      padding-left: 56px;
      font-size: 20px;
      line-height: 28px;
      color: #222222;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        margin: 12px 0;
      }
    }
  }
  .hr {
    margin-top: 96px;
    width: 100%;
    height: 8px;
    background: #f7f7f7;
  }
  .code {
    align-items: center;
    justify-content: center;
    &_l {
      img {
        width: 280px;
      }
    }
    &_c {
      margin-left: 100px;
      img {
        width: 56px;
      }
    }
    &_r {
      img {
        width: 460px;
      }
    }
  }
}
.footer {
  width: 100%;
  height: 120px;
  background: #171e26;
  font-size: 13px;
  line-height: 120px;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  a{
    margin-left: 10px;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.8);
  }
}
