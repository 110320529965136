//Desktop base: 16px
.f56 {
  font-size: 3.5rem;
}
.f42 {
  font-size: 2.625rem;
}
.f40 {
  font-size: 2.5rem;
}
.f32 {
  font-size: 2rem;
}
.f30 {
  font-size: 1.875rem;
}
.f28 {
  font-size: 1.75rem;
}
.f24 {
  font-size: 1.5rem;
}
.f20 {
  font-size: 1.25rem;
}
.f18 {
  font-size: 1.125rem;
}
.f16 {
  font-size: 1rem;
}
.f15 {
  font-size: 0.9375rem;
}
.f14 {
  font-size: 0.875rem;
}
.f13 {
  font-size: 0.8125rem;
}
.f12 {
  font-size: 0.75rem;
}
.f10 {
  font-size: 0.75rem;
  width: 118%;
  -webkit-transform: scale(0.82) translate(-9%, 0px);
  -moz-transform: scale(0.82) translate(-9%, 0px);
  -ms-transform: scale(0.82) translate(-9%, 0px);
  -o-transform: scale(0.82) translate(-9%, 0px);
  transform: scale(0.82) translate(-9%, 0px);
}


each(@breakpoint,{
  @media  screen and (min-width: @value){
    .f56-@{key}{
      font-size: 3.5rem;
    }
    .f42-@{key}{
      font-size: 2.625rem;
    }
    .f40-@{key}{
      font-size: 2.5rem;
    }
    .f32-@{key}{
      font-size: 2rem;
    }
    .f30-@{key}{
      font-size: 1.875rem;
    }
    .f28-@{key}{
      font-size: 1.75rem;
    }
    .f24-@{key}{
      font-size: 1.5rem;
    }
    .f20-@{key}{
      font-size: 1.25rem;
    }
    .f18-@{key}{
      font-size: 1.125rem;
    }
    .f16-@{key}{
      font-size: 1rem;
    }
    .f15-@{key}{
      font-size: 0.9375rem;
    }
    .f14-@{key}{
      font-size: 0.875rem;
    }
    .f13-@{key}{
      font-size: 0.8125rem;
    }
    .f12-@{key}{
      font-size: 0.75rem;
    }
    .f10-@{key}{
      font-size: 0.75rem;
      width: 118%;
      -webkit-transform: scale(0.82) translate(-9%, 0px);
      -moz-transform: scale(0.82) translate(-9%, 0px);
      -ms-transform: scale(0.82) translate(-9%, 0px);
      -o-transform: scale(0.82) translate(-9%, 0px);
      transform: scale(0.82) translate(-9%, 0px);
    }
    
  }})

h1 { 
  font-size: 24px;
}
h2 { 
  font-size: 22px;
}
h3 { 
  font-size: 18px;
}
h4 { 
  font-size: 16px;
}
h5 { 
  font-size: 12px;
}
h6 {
   font-size: 10px;
}
