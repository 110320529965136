
/* ==================
          文本
 ==================== */

 .text-xs {
	font-size: 20upx;
}

.text-sm {
	font-size: 24upx;
}

.text-df {
	font-size: 28upx;
}

.text-lg {
	font-size: 32upx;
}

.text-xl {
	font-size: 36upx;
}

.text-xxl {
	font-size: 44upx;
}
.text-xxxl {
	font-size: 52upx;
}

.text-sl {
	font-size: 80upx;
}

.text-xsl {
	font-size: 120upx;
}


/*  -- flex弹性布局 -- */

.flex {
	display: flex;
}

.basis-xs {
	flex-basis: 20%;
}

.basis-sm {
	flex-basis: 40%;
}

.basis-df {
	flex-basis: 50%;
}

.basis-lg {
	flex-basis: 60%;
}

.basis-xl {
	flex-basis: 80%;
}

.flex-sub {
	flex: 1;
}

.flex-twice {
	flex: 2;
}

.flex-treble {
	flex: 3;
}

.flex-direction {
	flex-direction: column;
}

.flex-wrap {
	flex-wrap: wrap;
}

.align-start {
	align-items: flex-start;
}

.align-end {
	align-items: flex-end;
}

.align-center {
	align-items: center;
}

.align-stretch {
	align-items: stretch;
}

.self-start {
	align-self: flex-start;
}

.self-center {
	align-self: flex-center;
}

.self-end {
	align-self: flex-end;
}

.self-stretch {
	align-self: stretch;
}

.align-stretch {
	align-items: stretch;
}

.justify-start {
	justify-content: flex-start;
}

.justify-end {
	justify-content: flex-end;
}

.justify-center {
	justify-content: center;
}

.justify-between {
	justify-content: space-between;
}

.justify-around {
	justify-content: space-around;
}
