
.u-d-none{
  display: none !important;
}
.u-d-inline{
  display: inline !important;
}
.u-d-inline-block{
  display: inline-block !important;
}
.u-d-block{
  display: block !important;
}
.u-d-table{
  display: table !important;
}
.u-d-table-row{
  display: table-row !important;
}
.u-d-table-cell{
  display: table-cell !important;
}
.u-d-flex{
  display: flex !important;
}
.u-d-inline-flex{
  display: inline-flex !important;
}
.u-d-inherit{
  display: inherit !important;
}

each(@breakpoints,{
  @media (min-width: @value){
    @infix:@key;
    .u-d-none-@{infix} {
      display: none !important;
    }
    .u-d-inline-@{infix} {
      display: inline !important;
    }
    .u-d-inline-block-@{infix} {
      display: inline-block !important;
    }
    .u-d-block-@{infix} {
      display: block !important;
    }
    .u-d-table-@{infix} {
      display: table !important;
    }
    .u-d-table-row-@{infix} {
      display: table-row !important;
    }
    .u-d-table-cell-@{infix} {
      display: table-cell !important;
    }
    .u-d-flex-@{infix} {
      display: flex !important;
    }
    .u-d-inline-flex-@{infix} {
      display: inline-flex !important;
    }
  }
})
