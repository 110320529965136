.u-flex-grow-full {
  flex-grow: 1;
}
.u-flex-row {
  flex-direction: row;
}
.u-flex-column {
  flex-direction: column;
}
.u-flex-row-reverse {
  flex-direction: row-reverse;
}
.u-flex-column-reverse {
  flex-direction: column-reverse;
}

.u-flex-wrap {
  flex-wrap: wrap;
}
.u-flex-nowrap {
  flex-wrap: nowrap;
}
.u-flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.u-justify-content-start {
  justify-content: flex-start;
}
.u-justify-content-end {
  justify-content: flex-end;
}
.u-justify-content-center {
  justify-content: center;
}
.u-justify-content-between {
  justify-content: space-between;
}
.u-justify-content-around {
  justify-content: space-around;
}
.u-justify-content-evenly {
  justify-content: space-evenly;
}

.u-align-items-start {
  align-items: flex-start;
}
.u-align-items-end {
  align-items: flex-end;
}
.u-align-items-center {
  align-items: center;
}
.u-align-items-baseline {
  align-items: baseline;
}
.u-align-items-stretch {
  align-items: stretch;
}

.u-align-content-start {
  align-content: flex-start;
}
.u-align-content-end {
  align-content: flex-end;
}
.u-align-content-center {
  align-content: center;
}
.u-align-content-between {
  align-content: space-between;
}
.u-align-content-around {
  align-content: space-around;
}
.u-align-content-stretch {
  align-content: stretch;
}

.u-align-self-auto {
  align-self: auto;
}
.u-align-self-start {
  align-self: flex-start;
}
.u-align-self-end {
  align-self: flex-end;
}
.u-align-self-center {
  align-self: center;
}
.u-align-self-baseline {
  align-self: baseline;
}
each(@breakpoints,{
  @media  screen and (min-width: @value){
      @infix: @key;
      .u-flex-grow-@{infix}-full      { flex-grow: 1 !important; };
      .u-flex-@{infix}-row            { flex-direction: row !important; }
      .u-flex-@{infix}-column         { flex-direction: column !important; }
      .u-flex-@{infix}-row-reverse    { flex-direction: row-reverse !important; }
      .u-flex-@{infix}-column-reverse { flex-direction: column-reverse !important; }
  
      .u-flex-@{infix}-wrap         { flex-wrap: wrap !important; }
      .u-flex-@{infix}-nowrap       { flex-wrap: nowrap !important; }
      .u-flex-@{infix}-wrap-reverse { flex-wrap: wrap-reverse !important; }
  
      .u-justify-content-@{infix}-start   { justify-content: flex-start !important; }
      .u-justify-content-@{infix}-end     { justify-content: flex-end !important; }
      .u-justify-content-@{infix}-center  { justify-content: center !important; }
      .u-justify-content-@{infix}-between { justify-content: space-between !important; }
      .u-justify-content-@{infix}-around  { justify-content: space-around !important; }
      .u-justify-content-@{infix}-evenly  { justify-content: space-evenly !important; }
  
      .u-align-items-@{infix}-start    { align-items: flex-start !important; }
      .u-align-items-@{infix}-end      { align-items: flex-end !important; }
      .u-align-items-@{infix}-center   { align-items: center !important; }
      .u-align-items-@{infix}-baseline { align-items: baseline !important; }
      .u-align-items-@{infix}-stretch  { align-items: stretch !important; }
  
      .u-align-content-@{infix}-start   { align-content: flex-start !important; }
      .u-align-content-@{infix}-end     { align-content: flex-end !important; }
      .u-align-content-@{infix}-center  { align-content: center !important; }
      .u-align-content-@{infix}-between { align-content: space-between !important; }
      .u-align-content-@{infix}-around  { align-content: space-around !important; }
      .u-align-content-@{infix}-stretch { align-content: stretch !important; }
  
      .u-align-self-@{infix}-auto     { align-self: auto !important; }
      .u-align-self-@{infix}-start    { align-self: flex-start !important; }
      .u-align-self-@{infix}-end      { align-self: flex-end !important; }
      .u-align-self-@{infix}-center   { align-self: center !important; }
      .u-align-self-@{infix}-baseline { align-self: baseline !important; }
  }
});

.spacing(){
  10: 10%;
  20: 20%;
  30: 30%;
  40: 40%;
  45: 45%;
  50: 50%;
  60: 60%;
  70: 70%;
  80: 80%;
  90: 90%;
  100: 100%;
}
  each(.spacing(),.(@v,@k,@i){
    .u-flex-@{k}{ flex:0 1 @v !important;}
  })