html,
body {
  height: 100%;
}

body {
  z-index: 0;
  width: 100%;
  background-position: top center;
  background-repeat: no-repeat;
  font-family: "PingFang SC" !important;
  //background: @page_background
  //-webkit-font-smoothing: antialiased
  @media screen and (max-width: @break-mid) {
    -webkit-text-size-adjust: 100% !important;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  body {
    font-family: "PingFang SC" !important;
  }
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  outline: none;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

blockquote,
q {
  quotes: none;
}

blockquote {
  &:before,
  &:after {
    content: "";
    content: none;
  }
}

q {
  &:before,
  &:after {
    content: "";
    content: none;
  }
}

hr {
  border: 1px solid @bd-gray;
  border-bottom: none;
}

.clear {
  position: relative;
  clear: both;
}

.left {
  float: left;
}

.right {
  float: right;
}

strong {
  font-weight: bold;
}

textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
  height: auto;
}

textarea {
  outline: none;
}

.nowrap{
  white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}